import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { GridsterItemComponentInterface } from 'angular-gridster2';
import { BaseQuery, BaseQueryInputColumnDescription, ColumnFilteringNumerical, ColumnFilteringRelativeTimestamp, ColumnFilteringString, ColumnFilteringTimestamp, ColumnGroupingDescription, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { WidgetBase } from '../../widget-base';
import { LinkedWidgetChangeParameters, WidgetOutputChangeParameters, XprojWidgetService } from '../../xproj-widget-service';
import { Cubical3dColumnConfig, Cubical3dWidgetConfig, Cubical3dWidgetQuery } from '../cubical3d-widget-config/xproj-cubical3d-widget-config-service';
import { SVG } from '@svgdotjs/svg.js'
import { ArrayUtils } from '../../../utils/array-utils-service';
import { GroupSelectionTypes, OutputDataType, WidgetOutputParameter } from '../../widget-config-service';
import { WidgetUtils } from '../../../utils/widget-utils-service';
import { SvgTextDirective } from 'ngx-svg/src/app/modules/directives/svg-text.directive';
import { SvgContainerComponent } from 'ngx-svg/src/app/modules/components/svg-container/svg-container.component';

import { Engine, Scene, ArcRotateCamera, HemisphericLight, Vector3, MeshBuilder, Mesh, FreeCamera, StandardMaterial, Color3, Color4, ValueCondition, BabylonFileLoaderConfiguration } from "babylonjs";
import { DateHelper } from '../../../helpers/date-helper-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';
import {XprojLuaDashboardWrapper} from '../../../dashboard/xproj-lua';
import * as fengari from 'fengari-web';
import { XPROJBIMSERVICE, XprojBimService } from '../../../services/xproj-bim.service';

export class cubePoint
{
  pos : BABYLON.Vector3 = new BABYLON.Vector3();
  lghid: string = "";
  value: number = 0;
}

@Component({
  selector: 'xproj-cubical3d-widget',
  templateUrl: './xproj-cubical3d-widget.component.html',
  styleUrls: ['./xproj-cubical3d-widget.component.scss']
})
export class XprojCubical3dWidgetComponent extends WidgetBase implements OnInit, OnDestroy, AfterViewInit {

  private xprojBimService: XprojBimService = inject(XPROJBIMSERVICE);

  widgetConfig: Cubical3dWidgetConfig;
  id: any;
  loading = false;
  private initiated: boolean = false;
  private updateSvgSize: boolean = true;

  lua : XprojLuaDashboardWrapper = null;


  private engine: BABYLON.Engine;

  clicktimer: any;

  constructor(
    @Inject(LOGGERSERVICE) public logger: XprojLoggerService,
    public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService) {
      super(logger, xprojClient, widgetService);
      this.lua = widgetService.lua;
  }



  private scene: BABYLON.Scene;
  private canvas: HTMLCanvasElement;
  cubePoints: cubePoint[]  = [];

  Lua_MESH_setColor(id: string,  r: number,g : number, b: number, a:number)
  {
    // debugger;
    var mesh = this.scene.getMeshById(id);// as BABYLON.Mesh;
    var colors = mesh.getVerticesData(BABYLON.VertexBuffer.ColorKind);
    
    if (!colors) 
    {
      let nbVertices = mesh.getPositionData().length;
      colors = new Array(4 * nbVertices);
      colors = colors.fill(0);
    }

    for (let i = 0 ; i < colors.length; )
    {      
      colors[i++] = r;
      colors[i++] = g;
      colors[i++] = b;
      colors[i++] = a;
    }
    mesh.setVerticesData(BABYLON.VertexBuffer.ColorKind, colors);

    if(a > 0.99999)
      return;

    let material = this.scene.getMaterialById(id);
    if(!material)
    {
      material = new BABYLON.StandardMaterial(id, this.scene);
      mesh.material = material;
    }
    material.alpha = a;

    //mesh.vertices
    
    // var myMaterial = new BABYLON.StandardMaterial("myMaterial", this.scene);
    // myMaterial.diffuseColor = new Color3(1, 1, 1);
    // myMaterial.specularColor = new Color3(0.5, 0.6, 0.87);
    // myMaterial.emissiveColor = new Color3(1, 1, 1);
    // myMaterial.ambientColor = new Color3(0.23, 0.98, 0.53);
    // myMaterial.alpha = 0.5;
  }

  LuaCreateHemisphericalLight(id: string, x:number, y:number, z:number, intensity: number)
  {
    var light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(x, y, z), this.scene);

    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = intensity;//0.7;
  }

  Lua_MESH_AddRotation(id:string, x: number, y: number, z:number)
  {
    let m = this.scene.getMeshById(id);
    m.rotation.x += x;
    m.rotation.y += y;
    m.rotation.z += z;
  }

  Lua_MESH_SetRotation(id:string, x: number, y: number, z:number)
  {
    let m = this.scene.getMeshById(id);
    m.rotation.x = x;
    m.rotation.y = y;
    m.rotation.z = z;
  }

  Lua_MESH_createBox( id:string, x:number,y :number,z: number, wx:number,wy:number,wz:number)
  {
    //{ width: nrWidth*spacingFactor, height:nrHeight*spacingFactor, depth:nrDepth*spacingFactor, faceColors: scolors }

    var cube = BABYLON.MeshBuilder.CreateBox(id,  { width: wx,  height:wy,  depth:wz, updatable: true });
    cube.position.x = x;
    cube.position.y = y;
    cube.position.z = z;
  

    // cube.position.y = y * spacingFactor;
    // cube.position.x = x * spacingFactor - (nrWidth/2)*1.1;
    // cube.position.z = z * spacingFactor;

    // cube.material = myMaterial as any;
  }

  Lua_SCENE_setclearcolor(r:number, g:number, b:number,a:number)
  {
    this.scene.clearColor = new Color4(r, g, b, a);
  }
  Lua_SCENE_setenvironmentintensity(intensity:number)
  {
    this.scene.environmentIntensity = intensity;
  }  

  // https://forum.babylonjs.com/t/how-can-i-make-the-transparent-background/1216/7
  async loadScene()
  {    
    return;
    const filename = "habitat7";//"habitat7.babylon";
    //const root = "https://demo.robotrisa.com/api/v1/file/nocache/";
    const root = "/api/v1/file/nocache/";
    console.log("Loading " + root+filename);

    await BABYLON.SceneLoader.AppendAsync(root, filename, this.scene);
    console.log("Loaded..");

    this.scene.clearColor = new Color4(0.2, 0.2, 0.3, 0.01);
    this.scene.environmentIntensity  = 0;


    var camera = new BABYLON.ArcRotateCamera("camera1", 0,0,10000, new BABYLON.Vector3(0,0,0), this.scene);

    // This targets the camera to scene origin
    camera.setTarget(new BABYLON.Vector3( 0,0, 0 ));

    // // This attaches the camera to the canvas
    camera.attachControl(this.canvas, true, true);

    // this.scene.setActiveCameraById("camera1");

    //this.scene.createDefaultCameraOrLight(true, true, true);
    // BABYLON.SceneLoader.Load(root, filename,this.engine, (scene: BABYLON.Scene) => {
    //   console.log("Loaded!");
    //   that.scene = scene;


    //   //let camera = new BABYLON.FollowCamera("camera1", new BABYLON.Vector3(0,0,-100), this.scene, this.scene.meshes[0]);
    //    var camera = new BABYLON.ArcRotateCamera("camera1", 0,0,0, new BABYLON.Vector3(0, 0, 0), that.scene);
    //   // // This targets the camera to scene origin
    //    camera.setTarget(new BABYLON.Vector3( 0, 0, 0 ));
    //   // // This attaches the camera to the canvas
    //   //camera.attachControl();
    //   camera.attachControl(that.canvas, true, true);

      /*console.log("Creating camera");

      camera = new BABYLON.FreeCamera("camera1", new BABYLON.Vector3(0, 0, -100000), that.scene);

      // This targets the camera to scene origin
      camera.setTarget(BABYLON.Vector3.Zero());

      // This attaches the camera to the canvas
      camera.attachControl(that.canvas, true);

      console.log("Loaded and camera..");*/
  //});
  }
  runDataUpdateScript()
  {
    let nrDepth = 2;
    let nrHeight = 3;
    let nrWidth = 4;


    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    // var light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), this.scene);

    // // Default intensity is 1. Let's dim the light a small amount
    // light.intensity = 0.7;

    // var myMaterial = new BABYLON.StandardMaterial("myMaterial", this.scene);

    // myMaterial.diffuseColor = new Color3(1, 1, 1);
    // myMaterial.specularColor = new Color3(0.5, 0.6, 0.87);
    // myMaterial.emissiveColor = new Color3(1, 1, 1);
    // myMaterial.ambientColor = new Color3(0.23, 0.98, 0.53);
    // myMaterial.alpha = 0.5;


/*
    let spacingFactor = 1.1;
    let cubeSize = 0.5;

    let scolor = new Color4(0.4,0.4,0.4);
    let scolors = [];
    for (let j = 0; j < 6; j++) {
      scolors.push(scolor);
    }

    let surroundingCube = BABYLON.MeshBuilder.CreateBox("cube", { width: nrWidth*spacingFactor, height:nrHeight*spacingFactor, depth:nrDepth*spacingFactor, faceColors: scolors });
    surroundingCube.position.x = -cubeSize*spacingFactor; // rätt
    surroundingCube.position.y = cubeSize*spacingFactor*2;
    surroundingCube.position.z = cubeSize*spacingFactor; // rätt
    surroundingCube.material = myMaterial as any;

    for (let z = 0; z < nrDepth; z++) {
      for (let y = 0; y < nrHeight; y++) {
        for (let x = 0; x < nrWidth; x++) {
          let color = new Color4(z / 2, y / 3, x / 4, 1);
          let colors = [];
          for (let j = 0; j < 6; j++) {
            colors.push(color);
          }
          var cube = BABYLON.MeshBuilder.CreateBox("cube" + x.toString() + y.toString() + z.toString(), { size: cubeSize, faceColors: colors });
          cube.position.y = y * spacingFactor;
          cube.position.x = x * spacingFactor - (nrWidth/2)*1.1;
          cube.position.z = z * spacingFactor;

          cube.material = myMaterial as any;
        }
      }
    }

    //this.scene.getMeshByName("cube121").position.x = 1000;*/

    //this.loadScene();

    if(this.widgetConfig.OnDataUpdateLuaScript && this.widgetConfig.OnDataUpdateLuaScript.length > 0)
      this.lua.RunScript(this.widgetConfig.OnDataUpdateLuaScript);
  }

  Lua_SceneLoad(rootUrl:string, filename:string)
  {
    BABYLON.SceneLoader.Append(rootUrl, filename, this.scene);
  }

  extraInternalLua = "";

  registerLua() : number
  {
    let nrPops = 0;
    {
      fengari.interop.push(fengari.L, this);
      fengari.lua.lua_setglobal(fengari.L, "scene");
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_SceneLoad
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_scene_load");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_MESH_createBox
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_mesh_createbox");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_MESH_setColor
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_mesh_setcolor");
      }
      nrPops++;
    }   
    {
      {
        fengari.interop.push(
          fengari.L,
          this.LuaCreateHemisphericalLight
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_light_createhemispherical");
      }
      nrPops++;
    } 
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_MESH_AddRotation
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_mesh_addrotation");
      }
      nrPops++;
    }     
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_MESH_SetRotation
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_mesh_setrotation");
      }
      nrPops++;
    }        
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_SCENE_setclearcolor
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_scene_setclearcolor");
      }
      nrPops++;
    }            
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_SCENE_setenvironmentintensity
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_scene_setenvironmentintensity");
      }
      nrPops++;
    }
    
    // BMS 3D
    fengari.interop.push(fengari.L, this.xprojBimService);
    fengari.lua.lua_setglobal(fengari.L, "xprojBimService");
    {
      {
        fengari.interop.push(
          fengari.L,
          this.xprojBimService.getFloorplanFile
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_bms_get_floorplanfile");
      }
      nrPops++;
    }

    let extraInternalLuaBMS = `
    
    function bms_get_floorplan(id, customerid)
        local t = lua_bms_get_floorplanfile(xprojBimService, id,customerid)
        local co = coroutine.running()

        t["then"](t,
            function()
              coroutine.resume(co)
            end)
        coroutine.yield()
        return xprojBimService.latestLoaded;
    end
    `;
    
    //addBox
    //this.setColor

    this.extraInternalLua =   `

    function load_scene(rooturl, filename)
        lua_scene_load(scene, rooturl, filename)
    end

    function create_box(id, x, y, z, wx, wy, wz)
      lua_mesh_createbox(scene, id, x,y,z, wx,wy,wz)
    end

    function set_color(id, r,g,b,a)
      lua_mesh_setcolor(scene, id, r,g,b,a)
    end

    function create_light_hemispherical(id, x,y,z,intensity)
      lua_light_createhemispherical(scene, id, x,y,z,intensity)
    end  
    
    function add_rotation(id, x,y,z)
      lua_mesh_addrotation(scene, id, x,y,z)
    end   

    function set_rotation(id, x,y,z)
      lua_mesh_setrotation(scene, id, x,y,z)
    end     
    
    function set_clearcolor(r,g,b,a)
      lua_scene_setclearcolor(scene,r,g,b,a)
    end      

    function set_envintensity(intensity)
      lua_scene_setenvironmentintensity(scene,intensity)
    end      
` + "\n" + extraInternalLuaBMS;     

    return nrPops;
  }

  runBeforeRenderScript()
  {
    let nrPops = this.registerLua();    
    this.lua.RunScript(this.widgetConfig.BeforeRenderLuaScript, this.extraInternalLua, nrPops);
  }
  runInitScript()
  {
    //let nrDepth = 5;
    let nrHeight = 100;
    let nrWidth = 100;
    
    this.scene = new BABYLON.Scene(this.engine);

    if(this.widgetConfig.BeforeRenderLuaScript && this.widgetConfig.BeforeRenderLuaScript.length > 0 )
    {
      let that = this;
      this.scene.registerBeforeRender(function()
      {
        that.runBeforeRenderScript();
      });
    }

    //this.scene.clearColor = new Color4(0.5, 0.0, 0.5, 0);

    // This creates and positions a free camera (non-mesh)
    //var camera = new BABYLON.ArcRotateCamera("camera1", 0,1,1, new BABYLON.Vector3(-nrWidth/2, 0, -nrWidth*1.5), this.scene);
    //camera.useAutoRotationBehavior = true;

    var camera = new BABYLON.ArcRotateCamera("camera", Math.PI / 2, Math.PI / 2, 200, new BABYLON.Vector3(0,50,0), this.scene);    
    camera.attachControl(this.canvas, true);
    camera.useFramingBehavior = true;
    

    // This targets the camera to scene origin
    //camera.setTarget(new BABYLON.Vector3( 0, 0/*nrHeight/2*/, 0 ));

    // This attaches the camera to the canvas
    //camera.attachControl(this.canvas, true, true);
    

    // Entire createScene() should be wrapped into a default init script!

    if(!this.widgetConfig.InitLuaScript || this.widgetConfig.InitLuaScript.length == 0)
      return;

    

/*
const filename = "habitat7";//"habitat7.babylon";
    //const root = "https://demo.robotrisa.com/api/v1/file/nocache/";
    const root = "/api/v1/file/nocache/";
    console.log("Loading " + root+filename);

    await BABYLON.SceneLoader.AppendAsync(root, filename, this.scene);
    console.log("Loaded..");

    */
    let nrPops = this.registerLua();    
    this.lua.RunScript(this.widgetConfig.InitLuaScript, this.extraInternalLua, nrPops);

    //this.scene.clearColor = new Color4(0.2, 0.2, 0.3, 0.01);
    //this.scene.environmentIntensity  = 0;


    var camera = new BABYLON.ArcRotateCamera("camera1", 0,0,10000, new BABYLON.Vector3(0,0,0), this.scene);

    // This targets the camera to scene origin
    camera.setTarget(new BABYLON.Vector3( 0,0, 0 ));

    // // This attaches the camera to the canvas
    camera.attachControl(this.canvas, true, true);
  }


  createScene() {
    return;
    // This creates a basic Babylon Scene object (non-mesh)
    let nrDepth = 2;
    let nrHeight = 3;
    let nrWidth = 4;


    this.scene = new BABYLON.Scene(this.engine);

    this.scene.clearColor = new Color4(0.0, 0.0, 0.0, 0);

    // This creates and positions a free camera (non-mesh)
    var camera = new BABYLON.ArcRotateCamera("camera1", 0,0,1, new BABYLON.Vector3(-nrWidth/2, 0, -nrWidth*1.5), this.scene);

    // This targets the camera to scene origin
    camera.setTarget(new BABYLON.Vector3( 0, nrHeight/2, 0 ));

    // This attaches the camera to the canvas
    camera.attachControl(this.canvas, true, true);


    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    var light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), this.scene);

    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 0.7;
    return;

    var myMaterial = new BABYLON.StandardMaterial("myMaterial", this.scene);

    myMaterial.diffuseColor = new Color3(1, 1, 1);
    myMaterial.specularColor = new Color3(0.5, 0.6, 0.87);
    myMaterial.emissiveColor = new Color3(1, 1, 1);
    myMaterial.ambientColor = new Color3(0.23, 0.98, 0.53);
    myMaterial.alpha = 0.5;



    let spacingFactor = 1.1;
    let cubeSize = 0.5;

    let scolor = new Color4(0.4,0.4,0.4);
    let scolors = [];
    for (let j = 0; j < 6; j++) {
      scolors.push(scolor);
    }



    let surroundingCube = BABYLON.MeshBuilder.CreateBox("cube", { width: nrWidth*spacingFactor, height:nrHeight*spacingFactor, depth:nrDepth*spacingFactor, faceColors: scolors });
    surroundingCube.position.x = -cubeSize*spacingFactor; // rätt
    surroundingCube.position.y = cubeSize*spacingFactor*2;
    surroundingCube.position.z = cubeSize*spacingFactor; // rätt
    surroundingCube.material = myMaterial as any;

    for (let z = 0; z < nrDepth; z++) {
      for (let y = 0; y < nrHeight; y++) {
        for (let x = 0; x < nrWidth; x++) {
          let color = new Color4(z / 2, y / 3, x / 4, 1);
          let colors = [];
          for (let j = 0; j < 6; j++) {
            colors.push(color);
          }
          var cube = BABYLON.MeshBuilder.CreateBox("cube", { size: cubeSize, faceColors: colors });
          cube.position.y = y * spacingFactor;
          cube.position.x = x * spacingFactor - (nrWidth/2)*1.1;
          cube.position.z = z * spacingFactor;

          cube.material = myMaterial as any;
        }
      }
    }


    // Our built-in 'ground' shape.
    //var ground = BABYLON.MeshBuilder.CreateGround("ground", {width: 6, height: 6}, scene);

  }

  //   createScene()
  //   {
  //     // Create a basic BJS Scene object
  //     this.scene = new Scene(this.engine);
  //     // Create a FreeCamera, and set its position to {x: 0, y: 5, z: -10}
  //     var camera = new FreeCamera('camera1', new Vector3(0, 5, -10), this.scene);
  //     // Target the camera to scene origin
  //     camera.setTarget(Vector3.Zero());
  //     // Attach the camera to the canvas
  //     camera.attachControl(this.canvas, false);
  //     // Create a basic light, aiming 0, 1, 0 - meaning, to the sky
  //     var light = new HemisphericLight('light1', new Vector3(0, 1, 0), this.scene);
  //     // Create a built-in "sphere" shape; its constructor takes 6 params: name, segment, diameter, scene, updatable, sideOrientation
  //     var sphere = Mesh.CreateSphere('sphere1', 16, 2, this.scene, false, Mesh.FRONTSIDE);
  //     // Move the sphere upward 1/2 of its height
  //     sphere.position.y = 1;
  //     // Create a built-in "ground" shape; its constructor takes 6 params : name, width, height, subdivision, scene, updatable
  //     var ground = Mesh.CreateGround('ground1', 6, 6, 2, this.scene, false);
  //     // Return the created scene
  // }


  hslToRgb(h, s, l) : number[]{
    var r, g, b;

    if(s == 0){
        r = g = b = l; // achromatic
    }else{
        var hue2rgb = function hue2rgb(p, q, t){
            if(t < 0) t += 1;
            if(t > 1) t -= 1;
            if(t < 1/6) return p + (q - p) * 6 * t;
            if(t < 1/2) return q;
            if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
            return p;
        }

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = hue2rgb(p, q, h + 1/3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1/3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

  reBuildSceneWithData()
  {
    debugger;
    // This creates a basic Babylon Scene object (non-mesh)
    let nrDepth = 2;
    let nrHeight = 3;
    let nrWidth = 4;


    this.scene = new BABYLON.Scene(this.engine);

    this.scene.clearColor = new Color4(0.0, 0.0, 0.0, 0);



    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    var light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), this.scene);

    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 0.7;

    var myMaterial = new BABYLON.StandardMaterial("myMaterial", this.scene);

    myMaterial.diffuseColor = new Color3(1, 1, 1);
    myMaterial.specularColor = new Color3(0.5, 0.6, 0.87);
    myMaterial.emissiveColor = new Color3(1, 1, 1);
    myMaterial.ambientColor = new Color3(0.23, 0.98, 0.53);
    myMaterial.alpha = 0.5;



    let spacingFactor = 1.1;
    let cubeSize = 0.5;

    let scolor = new Color4(0.7,0.7,0.7);
    let scolors = [];
    for (let j = 0; j < 6; j++) {
      scolors.push(scolor);
    }

    let maxY = 0;
    let maxX = 0;
    for(let i = 0; i < this.cubePoints.length; i++)
    {
      if(this.cubePoints[i].pos.y>maxY)
        maxY = this.cubePoints[i].pos.y;
      if(this.cubePoints[i].pos.x>maxX)
        maxX = this.cubePoints[i].pos.x;
    }


    nrDepth = 2;
    nrHeight = maxY;
    nrWidth = maxX;

    // This is just to filter out dummmy data
    if(maxX>10)
      nrWidth = Math.min(maxX,4);

    this.logger.info(nrDepth,nrHeight,nrWidth,maxY,maxX);

    // for(let i = 0; i < this.cubePoints.length; i++)
    // {
    //   let p = this.cubePoints[i].pos;
    //   let v = this.cubePoints[i].value;

    //   let color = new Color4(0, 1, 1, 1);
    //   let colors = [];
    //   for (let j = 0; j < 6; j++) {
    //     colors.push(color);
    //   }
    //   var cube = BABYLON.MeshBuilder.CreateBox("cube", { size: cubeSize, faceColors: colors });
    //   cube.position.y = p.y * spacingFactor;
    //   cube.position.x = (p.x%2) * spacingFactor - (nrWidth/2)*1.1;
    //   cube.position.z = ((p.x+1)%2) * spacingFactor;

    //   cube.material = myMaterial as any;
    // }

     let surroundingCube = BABYLON.MeshBuilder.CreateBox("cube", { width: nrWidth*spacingFactor, height:nrHeight*spacingFactor, depth:nrDepth*spacingFactor, faceColors: scolors });
     surroundingCube.position.x = -cubeSize*spacingFactor; // rätt
     surroundingCube.position.y = cubeSize*nrHeight*spacingFactor - cubeSize;
     surroundingCube.position.z = cubeSize*spacingFactor; // rätt
     surroundingCube.material = myMaterial as any;



    for (let z = 0; z < nrDepth; z++) {
      for (let y = 0; y < nrHeight; y++) {
         for (let x = 0; x < nrWidth; x++) {
           let color = new Color4(0.5, 0.5, 0.5, 1);

           for(let i = 0; i < this.cubePoints.length; i++)
           {
             let p = this.cubePoints[i].pos;

             if(p.x%4 != x) continue;
             if((p.x+1)%2 != z) continue;
             if(p.y != y) continue;

             //this.logger.info("Found a point!!", p);

             let s = this.cubePoints[i].value;
             if( s < 18)
              s = 18;
            if( s > 28)
              s = 28;

            s = (s-18.0)/10.0;
            if(s>1)
              s = 1;


            let hslColors = this.hslToRgb(1-s, 1, 0.5);
            this.logger.info("from s ", s, " got hsl colors: ", hslColors);
            color.r = hslColors[0];
            color.g = hslColors[1];
            color.b = hslColors[2];
           }

           let colors = [];
           for (let j = 0; j < 6; j++) {
             colors.push(color);
           }
           var cube = BABYLON.MeshBuilder.CreateBox("cube", { size: cubeSize, faceColors: colors });
           cube.position.y = y * spacingFactor;
           cube.position.x = x * spacingFactor - (nrWidth/2)*1.1;
           cube.position.z = z * spacingFactor;

           cube.material = myMaterial as any;
           cube.parent = surroundingCube;
           cube.position.x -= surroundingCube.position.x;
           cube.position.y -= surroundingCube.position.y;
           cube.position.z -= surroundingCube.position.z;
         }
       }
     }

      // This creates and positions a free camera (non-mesh)
    var camera = new BABYLON.ArcRotateCamera("camera1", 0,0,nrHeight*1.2, new BABYLON.Vector3(-nrWidth/2, 0, -nrWidth*1.5), this.scene);

    // This targets the camera to scene origin
    camera.setTarget(new BABYLON.Vector3( 0, nrHeight/2, 0 ));

    // This attaches the camera to the canvas
    camera.attachControl(this.canvas, true, true);
    this.scene.registerBeforeRender(function()
    {
      surroundingCube.rotation.y += 0.005;
    });



  }

  ngAfterViewInit(): void {

    if (!this.widgetConfig.ControlledByMaster || !this.responsive) {
      let that = this;
      setTimeout(() => {
        this.update().then(x => this.initiated = true);
      }, 500);
    }

    this.canvas = document.getElementById(this.id) as HTMLCanvasElement;
    this.logger.info("canvas: ");
    this.logger.info(this.canvas);

    this.engine = new BABYLON.Engine(this.canvas, true, { preserveDrawingBuffer: true, stencil: true });

    let that = this;
    this.engine.runRenderLoop(function () {
      that.scene.render();
    });

    this.runInitScript();

    //   window.addEventListener('resize', function(){
    //     this.logger.info("resize!");
    //     that.engine.resize();
    // });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }


  async ngOnInit() {
    this.widgetConfig = this.config as Cubical3dWidgetConfig;
    this.id = this.widgetConfig.Id;
    this.id = this.id.replaceAll('-', '');

    await super.ngOnInit();
  }

  async onInit() {
    this.logger.info("I got on init");
  }

  async onRefresh() {
    this.update();
  }

  async update() {
    if (!this.widgetheight || !this.widgetwidth) {
      this.setWidgetSize(this.widgetConfig.Height, this.widgetConfig.Width);
    }

    this.logger.info("RESIZE!");
    setTimeout(() => {
      this.engine.resize();

      this.load();
    });
  }

  async onResized(component: GridsterItemComponentInterface) {
    this.setWidgetSize(this.getHeight(component), component?.width);
    setTimeout(() => {
      this.update();
    });
  }

  async onReset() {
    this.logger.info("I got onreset");
  }

  async onUpdateQuery() {
    this.update();

    //this.reBuildSceneWithData();
    this.runDataUpdateScript();
  }

  async onLinkedWidgetChanged(event: LinkedWidgetChangeParameters) {
    await this.load();
  }

  async onWidgetOutputChanged(event: WidgetOutputChangeParameters[]) {
    await this.load();
  }

  async onProjectionDataChanged(projectionIds: string[], force : boolean = false) {
    let i = this.widgetConfig.ConfigQueries.findIndex(q => projectionIds.findIndex(p => p == q.Query.targetprojectionid) >= 0);
    if (force || i >= 0) {
      await this.load(true);
    }
  }

  private setWidgetSize(height: number, width?: number): void {
    if (height != this.widgetheight || width != this.widgetwidth) {
      //this.updateCanvasSize = true;
      //this.resize();
    }

    if (height) {
      this.widgetheight = height;
    }
    this.widgetwidth = width ?? this.widgetheight;
  }


  getQuery(queryConfig: Cubical3dWidgetQuery): BaseQuery {
    let query: BaseQuery = new BaseQuery();

    query.targetprojectionid = queryConfig.ProjectionId;
    query.maxitems = 2000;
    query.targetgroup = queryConfig.Group;

    let filterId = 0;
    queryConfig.DataFilters.forEach(datafilter => {
      let columnFiltering = WidgetUtils.GetColumnFiltering(datafilter);

      if (columnFiltering) {
        columnFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(columnFiltering.queryfilterid);

        if (columnFiltering instanceof ColumnFilteringNumerical) {
          query.numericalfilters.push(columnFiltering);
        }
        else if (columnFiltering instanceof ColumnFilteringString) {
          query.stringfilters.push(columnFiltering);
        }
        else if (columnFiltering instanceof ColumnFilteringTimestamp) {
          query.timestampfilters.push(columnFiltering);
        }
        else if (columnFiltering instanceof ColumnFilteringRelativeTimestamp) {
          query.relativetimestampfilters.push(columnFiltering);
        }
      }
    });

    // if (queryConfig.UseColumnMatching) {
    //   let matchcol = new BaseQueryInputColumnDescription();
    //   matchcol.columnname = queryConfig.MatchColumnName;
    //   matchcol.columnoutname = "match";
    //   query.columns.push(matchcol);

    //   let valuecol = new BaseQueryInputColumnDescription();
    //   valuecol.columnname = queryConfig.ValueColumnName;
    //   valuecol.columnoutname = "value";
    //   query.columns.push(valuecol);
    //  }


    return query;

  }

  async load(forcereload: boolean = false) {
    if (this.loading) {
      return;
    }

    this.loading = true;

    this.cubePoints.length = 0;

    try {
      if (!this.inputParametersHasValue(true))
      {
      }
      else
      {
        await ArrayUtils.AsyncForEach(this.widgetConfig.ConfigQueries, async (configQuery: Cubical3dWidgetQuery) =>
        {
          let query = this.getQuery(configQuery);

          // // Aggregation input parameters
          // let i = 0;
          // query.columns.forEach(col => {
          //   let dataConfig = configQuery.DataConfigs[i];
          //   if (dataConfig.UseAggregationInputParameter) {
          //     col.columnaggregation = this.getParameterValue(dataConfig.AggregationInputParameterId, col.columnaggregation);
          //   }
          //   i++;
          // });

          //Projection input parameters
          if (configQuery.UseProjectionInputParameter) {
            query.targetprojectionid = this.getParameterValue(configQuery.ProjectionInputParameterId, configQuery.Query.targetprojectionid).value;
          }

          //Group input parameters
          if (configQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT) {
            query.targetgroup = this.getParameterValue(configQuery.GroupInputParameterId, configQuery.Query.targetgroup).value;
          }
          else if (configQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS) {
            query.targetgroup = [];
            configQuery.GroupInputParameterIds.forEach(id => {
              query.targetgroup.push(this.getParameterValue(id, '').value + '');
            });
          }


          WidgetUtils.SetQueryFilterValues(query, configQuery.DataFilters, (i, d) => this.getParameterValue(i, d));
          WidgetUtils.AddQueryTimeframe(query,
            this.from,
            this.to,
            configQuery.timestampColumnName,
            // this.useRelativeTimestamp ? this.relativeTimestamp : null
            null
          );

          let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forcereload, 'cubical3dwidget', this.config.Name);
          //this.logger.info('loading svg query data: data:');
          //this.logger.info(queryResult);

          let numericaldata = queryResult["datanumbers"];
          //let timestampdata = queryResult["datatimestamps"];
          let stringdata = queryResult["datastrings"];

          // if(configQuery.UseColumnMatching)
          // {
          //   let matchingValues = stringdata[0];
          //   let values =  null;
          //   let dt = queryResult["columns"][1]["datatype"];
          //   if (dt == XDataType.String) {
          //     throw new Error("Invalid value type");
          //     //values = stringdata[1];
          //   }
          //   else if (dt == XDataType.Timestamp) {
          //     throw new Error("Invalid value type");
          //     //values = timestampdata[0];
          //   }
          //   else if (dt == XDataType.Number) {
          //     values = numericaldata[0];
          //   }


          //   for (let i = 0; i < matchingValues.length; i++)
          //   {
          //     let lghid = matchingValues[i];
          //     if(lghid.length != 4)
          //       continue;
          //     // 1101

          //     let floor = lghid.substring(0,2);
          //     let apartment = lghid.substring(2,4);


          //     let data = values[i];

          //     let cp = new cubePoint();
          //     cp.pos.x = parseFloat(apartment);
          //     cp.pos.y = parseFloat(floor)-10.0;
          //     cp.lghid = lghid;
          //     cp.value = data;

          //     this.cubePoints.push( cp );
          //   }
          //   this.logger.info("Data points i created for cubez:", this.cubePoints);
          // }
          // else
          // {
          //   throw new Error("not implemented");
          // }

        });
      }
      //this.reBuildSceneWithData();
      this.runDataUpdateScript();
    }
    catch(err)
    {
      console.error(err);
    }


    this.loading = false;

  }


}
