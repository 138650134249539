import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { GetDataSourceInstancesRequest, GrpcDataSourceInstance, GrpcDataSourceInstanceNode, GrpcTreeNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { CacheService } from 'xproj-lib';
import { ArrayUtils } from 'xproj-lib';

var AsyncLock = require('async-lock');

export class DataSourceInstanceTreeNode {
  dataSourceInstance: GrpcDataSourceInstance;
  id: string;
  children: DataSourceInstanceTreeNode[] = null;
}

@Component({
  selector: 'app-configuration-workspace',
  templateUrl: './configuration-workspace.component.html',
  styleUrls: ['./configuration-workspace.component.scss'],
  host: {
    class: 'content-container'
  }
})
export class ConfigurationWorkspaceComponent implements OnInit, AfterViewInit {

  collapsed = false;
  initiated: boolean = false;
  lazy: boolean = true;

  lock = new AsyncLock({ timeout: 20000 });
  private readonly lockkey: string = 'configurationworkspacecomponentinitkey';
  private readonly dataSourceInstancesCacheKey : string = 'configurationworkspace_datasourceinstances';

  dataSourceInstances: GrpcDataSourceInstance[] = [];
  dataSourceInstanceRootNodes: DataSourceInstanceTreeNode[] = [];

  constructor(private xConfClient: XProjectorXConfClient,
    private readonly cache: CacheService) {

  }


  async ngOnInit() {
  }

  async ngAfterViewInit() {
    if (!this.initiated) {
      this.dataSourceInstances = await this.xConfClient.getDataSourceInstances(new GetDataSourceInstancesRequest({ includeCollections: true, includeSystemDefined: true, maxHops: 1 }));
      //this.dataSourceInstances = await this.xConfClient.getDataSourceInstances(new GetDataSourceInstancesRequest({maxHops: 1}));
      this.dataSourceInstances.forEach(dataSourceInstance => {
        let rootNode = new DataSourceInstanceTreeNode();
        rootNode.dataSourceInstance = dataSourceInstance;
        rootNode.id = dataSourceInstance.id;
        this.dataSourceInstanceRootNodes.push(rootNode);
      });
      this.initiated = true;
    }
  }

  getChildren = this.getChildNodes.bind(this);

  async getChildNodes(dataSourceInstanceNode: DataSourceInstanceTreeNode, force: boolean = false): Promise<DataSourceInstanceTreeNode[]> {
    let cacheKey = this.dataSourceInstancesCacheKey + dataSourceInstanceNode?.id
    if (this.cache.has(cacheKey)) {
      return Promise.resolve(this.cache.get(cacheKey));
    }
    else {
      let that = this;
      return new Promise<DataSourceInstanceTreeNode[]> (
        function (resolve) {
          try {
            if (dataSourceInstanceNode && dataSourceInstanceNode.dataSourceInstance) {
              that.lock.acquire(that.lockkey, async () => {
                if (!dataSourceInstanceNode.children || force) {

                  if (!that.lazy) {
                    let tree = await that.xConfClient.getDataSourceInstanceTree(dataSourceInstanceNode.id);
                    await that.addChildren(dataSourceInstanceNode, tree.children);
                  }
                  else {
                    dataSourceInstanceNode.children = [];

                    let childdataSourceInstances = await that.xConfClient.getDataSourceInstanceChildren(dataSourceInstanceNode.id);
                    childdataSourceInstances.forEach(dataSourceInstance => {
                      let node = new DataSourceInstanceTreeNode();
                      node.dataSourceInstance = dataSourceInstance;
                      node.id = dataSourceInstance.id;
                      dataSourceInstanceNode.children.push(node);
                    });
                  }
                }
                let result = dataSourceInstanceNode.children.sort((a, b) => {
                  return a.dataSourceInstance.name > b.dataSourceInstance.name ? 1 : -1;
                });
                that.cache.set(cacheKey, result, undefined, {
                  "TTL": 3600
                });
                resolve(result);
              }).then(() => {
                // lock released
              }).catch(() => {
                resolve([]);
              });
            }
            else {
              resolve([]);
            }
          }
          catch {
            return resolve([]);
          }
        }
      );
    }
  };

  async addChildren(parent: DataSourceInstanceTreeNode, grpcChildren: GrpcDataSourceInstanceNode[]) {
    parent.children = [];
    await ArrayUtils.AsyncForEach(grpcChildren, async (grpcChild: GrpcDataSourceInstanceNode) => {
      let child = new DataSourceInstanceTreeNode();
      child.dataSourceInstance = grpcChild.node;
      child.id = grpcChild.node.id;
      parent.children.push(child);

      child.children = [];
      if (grpcChild.children.length > 0) {
        await this.addChildren(child, grpcChild.children);
      }
    });
  }

  async selectItem(item: GrpcDataSourceInstance) {

  }
}
