<div class="clr-row">
  <div class="clr-col-7">
    <h1>Assign Building addresses</h1>
  </div>
  <div class="clr-col-5 save-button">
    <button type="button" class="btn btn-secondary" (click)="save() ;">
      Save
    </button>
    <button *ngIf="showSaved" class="btn btn-link" [disabled]="true">Building and floor saved.</button>
  </div>
</div>
<p>
  Assign directly adjacent building addresses to each other below.
  In the table assign a building adress which is <em>directly</em> north/south/west/east of another building address.
</p>
<div style="max-width:1200px;">
  <clr-datagrid class="datagrid-compact" [clrDgLoading]="loadingBuildingAddresses">

      <clr-dg-column [clrDgField]="'description'" [clrDgSortOrder]="ascSort"> Description </clr-dg-column>
      <clr-dg-column [clrDgField]="'northof'" > North Of </clr-dg-column>
      <clr-dg-column [clrDgField]="'southof'" > South Of </clr-dg-column>
      <clr-dg-column [clrDgField]="'westof'" > West Of </clr-dg-column>
      <clr-dg-column [clrDgField]="'eastof'" > East Of </clr-dg-column>

      <clr-dg-row *clrDgItems="let buildingaddr of buildingAddresses">
          <clr-dg-cell class="cellhideoverflow"> {{buildingaddr.description}} </clr-dg-cell>

          <clr-dg-cell class="cellhideoverflow">
            <clr-select-container style="margin-top: 0px;">
              <select clrSelect name="northof" [(ngModel)]="buildingaddr.northof">
                <option [ngValue]="''"></option>
                <option *ngFor="let baddr of buildingAddresses | filter_buildingaddress : buildingaddr" [ngValue]="baddr.id">{{baddr.description}}</option>
              </select>
            </clr-select-container>
          </clr-dg-cell>

          <clr-dg-cell class="cellhideoverflow">
            <clr-select-container style="margin-top: 0px;">
              <select clrSelect name="southof" [(ngModel)]="buildingaddr.southof">
                <option [ngValue]="''"></option>
                <option *ngFor="let baddr of buildingAddresses | filter_buildingaddress : buildingaddr" [ngValue]="baddr.id">{{baddr.description}}</option>
              </select>
            </clr-select-container>
          </clr-dg-cell>

          <clr-dg-cell class="cellhideoverflow">
            <clr-select-container style="margin-top: 0px;">
              <select clrSelect name="westof" [(ngModel)]="buildingaddr.westof">
                <option [ngValue]="''"></option>
                <option *ngFor="let baddr of buildingAddresses | filter_buildingaddress : buildingaddr" [ngValue]="baddr.id">{{baddr.description}}</option>
              </select>
            </clr-select-container>
          </clr-dg-cell>

          <clr-dg-cell class="cellhideoverflow">
            <clr-select-container style="margin-top: 0px;">
              <select clrSelect name="eastof" [(ngModel)]="buildingaddr.eastof">
                <option [ngValue]="''"></option>
                <option *ngFor="let baddr of buildingAddresses | filter_buildingaddress : buildingaddr" [ngValue]="baddr.id">{{baddr.description}}</option>
              </select>
            </clr-select-container>
          </clr-dg-cell>
      </clr-dg-row>

  </clr-datagrid>
</div>

<h2> Floorplans </h2>

Default floorplan for all entries to this building.
<app-bms-floorplan-editor #florplansEditor [nodeId]="nodeId" [nodeTypeId]="nodeTypeId" [customer]="customer"></app-bms-floorplan-editor>
