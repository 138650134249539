import { Component, OnInit, AfterViewInit, Output, EventEmitter, OnDestroy, Input, Inject, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { ChartWidgetConfig } from '../widgets/chart/chart-widget-config/xproj-chart-widget-config-service';
import { CopiedWidget, DashboardConfig, DashboardEvent, ReponsiveiveOverridableWidgetConfigs, Resolutions, ResponsiveOverriderableWidgetConfig, ScriptedParametersOnEvent, ScriptedParametersOnParameterChange, SideNavPosition } from './xproj-dashboard-service';
import { LinkedWidgetChangeParameters, LinkedWidgetSelectParameters, WidgetOutputChangeParameters, XprojWidgetService } from '../widgets/xproj-widget-service';
import { ClrLoadingState } from '@clr/angular';
import { OutputDataType, WidgetConfig, WidgetOutputParameter } from '../widgets/widget-config-service';
import { TableWidgetConfig } from '../widgets/table/table-widget-config/table-widget-config-service';
import { MasterWidgetConfig } from '../widgets/master/master-widget-config/master-widget-config-service';
import { BehaviorSubject, Subject, Subscription, interval, timer } from 'rxjs';
import { PieChartType, PiechartWidgetConfig } from '../widgets/piechart/piechart-widget-config/piechart-widget-config-service';
import { LabelWidgetConfig } from '../widgets/label/label-widget-config/label-widget-config-service';
import { GridsterConfig, GridsterItem, GridsterComponentInterface, GridsterItemComponentInterface, CompactType, DisplayGrid, GridType } from 'angular-gridster2';
import { GridOptions } from '../utils/gridster-utils-service';
import { SpectrumAnalyzerConfig } from '../widgets/spectrum-analyzer/spectrum-analyzer-widget-config/spectrum-analyzer-config-service';
import { DASHBOARDSERVICE, XprojDashboardService } from './xproj-dashboard-service';
import { WidgetType, XprojDashboardInteractService } from './xproj-dashboard-interact.service';
import { TextWidgetConfig } from '../widgets/text/text-widget-config/text-widget-config-service';
import { Aggregation, BaseQuery, BaseQueryInputColumnDescription, BaseQueryResult, ColumnFilteringString, DFTQuery, DownSampleQuery, FilterComparator, FilterLogicalGroup, FilterLogicalGroupType, MsgPackCloneObject, MultiseriesQuery, Projection, SPCQuery, XProjectorClient } from '../XProjector/xprojector-client-service';
import { ArrayUtils } from '../utils/array-utils-service';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { MapWidgetConfig } from '../widgets/map/map-widget-config/xproj-map-widget-config-service';
import { SvgWidgetConfig } from '../widgets/svg/svg-widget-config/xproj-svg-widget-config-service';
import { Cubical3dWidgetConfig } from '../widgets/cubical3d/cubical3d-widget-config/xproj-cubical3d-widget-config-service';
import { OutputControllerWidgetConfig } from '../widgets/output/output-controller-widget-config/xproj-output-controller-widget-config-service';
import { SplitAreaDirective, SplitComponent } from 'angular-split';
import { XprojModalService } from '../modals/xproj-modal-service.service';
import { TypedJSON } from 'typedjson';
import { saveAs } from 'file-saver';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ClipboardService } from 'ngx-clipboard'


import { ProjectionDataEditorWidgetConfig } from '../widgets/projection-dataeditor/projection-dataeditor-widget-config/projection-dataeditor-widget-config-service';
import { ContainerWidgetConfig } from '../widgets/container/container-widget-config/container-widget-config-service';
import { Guid } from '../utils/guid-service';
import { SpcConfig } from '../widgets/spc/spc-widget-config/spc-config-service';
import { LOGGERSERVICE, XprojLoggerService } from '../logger/xproj-logger-service';
import { environment } from 'src/environments/environment';
import {XprojLuaDashboardWrapper} from './xproj-lua'
import { DashboardOutputChangeParameters } from '../models/dashboard-output-change-parameters';
import { ScriptedParameters } from '../models/scripted-parameters';

export class WidgetExportParameters {
  name: string;
  queries: (BaseQuery | DownSampleQuery | DFTQuery | MultiseriesQuery | SPCQuery)[];
  decimals: number = 3;
  excludeColumns: string[] = [];
  data: any[][];
}

export const CUSTOMERID_OUTPUTPARAMETERID = '__CUSTOMER_ID__';
export const REMOTENODEID_OUTPUTPARAMETERID = '__REMOTENODE_ID__';

@Component({
  selector: 'xproj-dashboard',
  templateUrl: './xproj-dashboard.component.html',
  styleUrls: ['./xproj-dashboard.component.scss'],
  // host: {
  //   class: 'content-container'
  // }
})
export class XprojDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  targetResolution: Resolutions = Resolutions.Sandbox;

  @ViewChild("mainview", { read: ElementRef, static: false }) mainview: ElementRef;
  @ViewChild("sidenav", { read: ElementRef, static: false }) sidenav: ElementRef;
  @ViewChild("grid", { read: ElementRef, static: false }) grid: ElementRef;

  //@ViewChild("xprojuserstyles", { read: ElementRef, static: false }) xprojuserstyles : ElementRef;

  @ViewChild(SplitComponent) splitEl: SplitComponent
  @ViewChildren(SplitAreaDirective) areasEl: QueryList<SplitAreaDirective>

  @Output() onWidgetValueSelected = new EventEmitter<LinkedWidgetSelectParameters>();
  @Output() onWidgetValueChanged = new EventEmitter<LinkedWidgetChangeParameters>();
  @Output() onWidgetExport = new EventEmitter<WidgetExportParameters>();

  @Input() editMode: boolean = false;
  @Input() showDashboardSettings: boolean = false;

  @Input() set responsiveWidth(value: number) {
    if (!this.editMode)
      this.refreshResolutionView(value);
  }

  @Input('beforeSave') beforeSave: (dashboardConfig: DashboardConfig) => DashboardConfig;

  //@Input() dashboardConfig: DashboardConfig; // Commented out for creating responsive, add again
  dashboardConfig: DashboardConfig = null;
  originalDashboardConfig: DashboardConfig = null;

  @Input() dashboardId: string = '';
  @Input() dashboardTag: string = '';
  @Input() systemDashboard: boolean = false;
  @Input() assetDashboard: boolean = false;
  //@Input() isMobile: boolean = false;
  @Input() enableExport: boolean = false;
  @Input() enableSubscriptions: boolean = true;

  private _dashboardOutputParameters: DashboardOutputChangeParameters[] = [];
  @Input()
  get dashboardOutputParameters() {
    return this._dashboardOutputParameters;
  }
  set dashboardOutputParameters(params) {
    this._dashboardOutputParameters = params;
    this.updateParameters(this._dashboardOutputParameters);
  }

  dashboardVersion: number = -1;

  outputParametetersUpdated: boolean = false;
  subscriptionsEnabled: boolean = true;

  gridoptions: GridsterConfig = GridOptions;

  public enablePasteInGrid: boolean = false;
  selectedWidgetConfig: WidgetConfig = null;
  markedWidgetConfig: WidgetConfig = null;
  copiedWidgetConfig: WidgetConfig = null;

  ngUnsubscribe = new Subject<void>();

  loadingProjectionColumns = false;

  loadFromService: boolean = true;
  loading: ClrLoadingState = ClrLoadingState.DEFAULT;

  showSettings: boolean = false;
  editFullScreen: boolean = false;
  viewFullScreen: boolean = false;
  gridBackgroundColor: string = 'transparent';
  initDone: boolean = false;
  initCounter: number;
  sidenavCollapsed = true;
  editWidth: number = 600;
  lastEditFullScreen: boolean = false;

  exportFilename: string = 'dashboardexport.json';
  showExportDialog: boolean = false;
  showImportDialog: boolean = false;
  configfiles: NgxFileDropEntry[] = [];

  showLoadParameters: boolean = false;
  showLinkedParameters: boolean = false;

  gridWidgetConfigs: WidgetConfig[] = [];
  sidenavConfigs: WidgetConfig[] = [];

  widgetInitCounter: number;

  dataHasChanged: boolean = false;
  projectionIdsChanged: string[] = [];
  dataChangedTimerSource: any;
  dashboardWidth: string = "100%";
  dashboardStyle = { "width": "100%" };

  refreshDashboardTimerSource$: BehaviorSubject<number>;
  refreshDashboardTimerSubscription : Subscription;

  //private savetimersource = timer(5000, 5000);
  //private savetimersubscription: Subscription;

  selectedLinkedParmaeterTriggerOn = [];
  private parameterLastChanged: Map<string, Date> = new Map<string, Date>();
  public allParameters: Array<WidgetOutputParameter> = new Array<WidgetOutputParameter>()
  private hasSetupSubscribeOnParameters = false;


  modified: boolean = false;

  SideNavPosition = SideNavPosition;
  CompactType = CompactType;
  DisplayGrid = DisplayGrid;
  GridType = GridType;
  Resolutions = Resolutions;

  private widgetTypes: WidgetType[] =
    [
      {
        id: 'chart',
        name: 'Chart widget'
      },
      {
        id: 'container',
        name: 'Container widget'
      },
      {
        id: 'cubical3d',
        name: 'Cubical 3D'
      },
      {
        id: 'projectiondataeditor',
        name: 'Data editor widget'
      },
      {
        id: 'label',
        name: 'Label widget'
      },
      {
        id: 'map',
        name: 'Map widget'
      },
      {
        id: 'master',
        name: 'Master widget'
      },
      {
        id: 'output',
        name: 'Output widget'
      },
      {
        id: 'piechart',
        name: 'Piechart widget'
      },
      {
        id: 'spectrumanalyzer',
        name: 'Spectrum analyzer widget'
      },
      {
        id: 'svg',
        name: 'Svg widget'
      },
      {
        id: 'table',
        name: 'Table widget'
      },
      {
        id: 'text',
        name: 'Text widget'
      }
    ];

  lua : XprojLuaDashboardWrapper = null;

  constructor(
    @Inject(DASHBOARDSERVICE) private dashboardService: XprojDashboardService,
    public widgetService: XprojWidgetService,
    public xprojClient: XProjectorClient,
    private dashboardInteractService: XprojDashboardInteractService,
    private modalService: XprojModalService,
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
    private clipboard: ClipboardService)
  {
    this.lua = new XprojLuaDashboardWrapper(xprojClient, logger);
    this.lua.onUpdateAndUpsertDynamicParameters = this.updateAndUpsertDynamicParameters.bind(this);

    //this.editWidth
    let lseditWidth = Number.parseInt(localStorage.getItem("xprojector-editwidth") || this.editWidth.toString());
    if (lseditWidth != this.editWidth)
      this.editWidth = lseditWidth;
  }
  public inResponsiveEditingMode: boolean = false;
  dashboardRes = 0; // refactor to current width etc


  public removeWidgetFromResponsive(widgetConfig: WidgetConfig) {
    this.logger.info("should remove ", widgetConfig.Id);
    let targetResponsiveWidgets = this.dashboardConfig.ResponsiveWidgets.get(this.dashboardRes).OverrideConfigs;
    for (let i = 0; i < targetResponsiveWidgets.length; i++) {
      let wc = targetResponsiveWidgets[i];
      if (wc.OriginalId == widgetConfig.Id) {
        targetResponsiveWidgets.splice(i, 1);
        i--;
      }
    }
    this.applyResponsiveWidgets(this.dashboardRes);
  }

  public clearAllResponsitivity() {
    //new Map<Number, ReponsiveiveOverridableWidgetConfigs >();
    this.dashboardConfig.ResponsiveWidgets.clear();
    this.refreshResolution();
  }

  public clearAllWidgetsInResponsive() {
    let targetResponsiveWidgets = this.dashboardConfig.ResponsiveWidgets.get(this.dashboardRes);
    targetResponsiveWidgets.OverrideConfigs.length = 0;

    this.applyResponsiveWidgets(this.dashboardRes);
  }

  public addAllWidgetsToResponsive() {
    // if(this.dashboardConfig.ResponsiveWidgets[newRes] )
    //     {
    //       this.logger.info("i have responsive widgets..");
    //       this.applyResponsiveWidgets(newRes);
    //     }
    //     else
    //     {
    //       this.logger.info("i do not have responsive widgets..");
    //       let respWidgets = new ReponsiveiveOverridableWidgetConfigs();
    //       respWidgets.Resolution = newRes;
    //       this.dashboardConfig.ResponsiveWidgets[newRes] = respWidgets;
    //       this.dashboardConfig.WidgetConfigs.length = 0; //MsgPackCloneObject(this.originalDashboardConfig.WidgetConfigs) as WidgetConfig[];
    //       this.applyResponsiveWidgets(newRes);
    //     }
    // }

    let targetResponsiveWidgets = this.dashboardConfig.ResponsiveWidgets.get(this.dashboardRes);
    for (let w of this.originalDashboardConfig.WidgetConfigs) {
      let respW = new ResponsiveOverriderableWidgetConfig();
      respW.OriginalId = w.Id;
      respW.cols = w.cols;
      respW.rows = w.rows;
      respW.x = w.x;
      respW.y = w.y;

      //this.dashboardConfig.ResponsiveWidgets[this.dashboardRes].push(respW);
      targetResponsiveWidgets.OverrideConfigs.push(respW);
    }

    this.applyResponsiveWidgets(this.dashboardRes);
  }

  applyResponsiveWidgets(newRes) {
    this.dashboardRes = newRes;
    this.logger.info("applyResponsiveWidgets", newRes);
    if (!this.dashboardConfig.ResponsiveWidgets.has(newRes)) {
      this.dashboardConfig.WidgetConfigs = []; // this.originalDashboardConfig.WidgetConfigs;
      this.logger.info("applyResponsiveWidgets: no responsive widgets. returning");
      return;
    }

    this.dashboardConfig.WidgetConfigs.length = 0;

    let respWidgets = this.dashboardConfig.ResponsiveWidgets.get(newRes);
    // olof kolla om det är bättre med typedjson
    //let newWidgetConfig: WidgetConfig[] = [];
    for (let origConfig of this.originalDashboardConfig.WidgetConfigs) {
      for (let respWidget of respWidgets.OverrideConfigs) {
        if (respWidget.OriginalId != origConfig.Id)
          continue;

        let newWC = origConfig.Clone();
        newWC.cols = respWidget.cols;
        newWC.rows = respWidget.rows;
        newWC.x = respWidget.x;
        newWC.y = respWidget.y;
        //this.logger.info(newWC);
        this.dashboardConfig.WidgetConfigs.push(newWC);
      }
    }
    this.sortWidgetConfigs();
    //this.refresh();
  }

  saveResponsiveWidgets() {
    this.logger.info("saveResponsiveWidgets ", this.dashboardRes);
    let targetResponsiveWidgets = new ReponsiveiveOverridableWidgetConfigs();
    targetResponsiveWidgets.Resolution = this.dashboardRes;
    let respWidgets = this.dashboardConfig.ResponsiveWidgets.get(this.dashboardRes);

    for (let origConfig of this.dashboardConfig.WidgetConfigs) {
      for (let respWidget of respWidgets.OverrideConfigs) {
        if (respWidget.OriginalId != origConfig.Id)
          continue;

        let respW = new ResponsiveOverriderableWidgetConfig();
        respW.OriginalId = respWidget.OriginalId;
        respW.cols = origConfig.cols;
        respW.rows = origConfig.rows;
        respW.x = origConfig.x;
        respW.y = origConfig.y;

        //this.dashboardConfig.ResponsiveWidgets[this.dashboardRes].push(respW);
        targetResponsiveWidgets.OverrideConfigs.push(respW);
      }
    }
    this.dashboardConfig.ResponsiveWidgets.set(this.dashboardRes, targetResponsiveWidgets);
  }

  relinkWidgetsToOriginal() {
    this.logger.info("relinkWidgetsToOriginal");
    this.dashboardConfig.WidgetConfigs.length = 0;
    for (let ow of this.originalDashboardConfig.WidgetConfigs) {
      this.logger.info("Pushing ", ow);
      this.dashboardConfig.WidgetConfigs.push(ow);
    }
    this.sortWidgetConfigs();
    this.logger.info("Dashboard config now has widgetconfigs: ", this.dashboardConfig.WidgetConfigs);
  }

  public getPixelWidthResolution(Res: Resolutions) {
    switch (Res) {
      case Resolutions.Sandbox:
        return 1800;
      case Resolutions.Mobile:
        return 400;
      case Resolutions.HD:
        return 1900;
      case Resolutions.QHD:
        return 2560;
      case Resolutions.UHD:
        return 3840;
      case Resolutions.IPad:
        return 750;
    }
    throw new Error("Invalid Resolution");
  }

  public getResolutionFromPixelWidth(width: number) {
    if (width < Resolutions.IPad)
      return Resolutions.Mobile;

    if (width < Resolutions.HD)
      return Resolutions.IPad;

    if (width < Resolutions.QHD)
      return Resolutions.HD;

    if (width < Resolutions.UHD)
      return Resolutions.QHD;

    return Resolutions.UHD;
  }

  public IsTouch = false;

  private RequestedWidth: number = 0;
  private oldActualTargetRes: number = 0;

  justSetDashboardWidth() {
    this.dashboardWidth = this.RequestedWidth.toString() + "px";

    this.dashboardStyle["margin-left"] = "auto";
    this.dashboardStyle["margin-right"] = "auto";
    this.dashboardStyle["box-shadow"] = "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)";
    this.dashboardStyle["width"] = this.dashboardWidth;
  }

  public refreshResolutionView(width: number = -1) {
    if (width > -1 && width < 100)
      return;

    let hasRespForRes = false;
    this.logger.info("refreshResolutionView", width);
    if (width != -1)
      this.RequestedWidth = width;

    this.logger.info("RequestedWidth", this.RequestedWidth);

    if (!this.dashboardConfig) {
      this.justSetDashboardWidth();
      this.refresh();
      return;
    }
    if (!this.dashboardConfig.ResponsiveWidgets) {
      this.justSetDashboardWidth();
      this.refresh();
      return;
    }
    if (this.dashboardConfig.ResponsiveWidgets.size == 0) {
      this.justSetDashboardWidth();
      this.refresh();
      return;
    }

    this.logger.info("refreshResolutionView finding key");

    let foundKey = -1;
    let foundValue = null;
    for (let [key, value] of this.dashboardConfig.ResponsiveWidgets) {
      let respWidgets = this.dashboardConfig.ResponsiveWidgets.get(key);
      if (respWidgets.OverrideConfigs.length == 0)
        continue;

      if (key <= this.RequestedWidth) {
        if (key > foundKey) {
          foundKey = key as number;
          foundValue = value;
          hasRespForRes = true;
        }
      }
    }
    this.logger.info("selected from < request, key/value", foundKey, foundValue);

    if (foundKey == -1) {
      // Todo: refactor this mess :D
      foundKey = 100000;

      for (let [key, value] of this.dashboardConfig.ResponsiveWidgets) {
        let respWidgets = this.dashboardConfig.ResponsiveWidgets.get(key);
        if (respWidgets.OverrideConfigs.length == 0)
          continue;

        if (key < foundKey) {
          foundKey = key as number;
          foundValue = value;
          hasRespForRes = true;
        }
      }

      this.logger.info("selected from > request, key/value", foundKey, foundValue);
    }


    this.justSetDashboardWidth();

    if (!hasRespForRes) {
      this.refresh();
      return;
    }

    if (foundKey != this.oldActualTargetRes) {
      this.oldActualTargetRes = foundKey;
      this.applyResponsiveWidgets(foundKey);
    }
  }

  public refreshResolution() {
    let wasInResponsive = this.inResponsiveEditingMode;

    if (!this.dashboardConfig || !this.dashboardConfig.ResponsiveWidgets) {
      this.logger.info("refreshResolution before load?");
      return;
    }
    //this.originalDashboardConfig = MsgPackCloneObject(this.dashboardConfig) as DashboardConfig;

    let oldResolution = this.dashboardWidth;
    let MarginPX = 400;
    let MarginTablet = 50;
    let newRes = 0;

    switch (this.targetResolution) {
      case Resolutions.Sandbox:
        newRes = this.getPixelWidthResolution(this.targetResolution);
        this.dashboardWidth = newRes.toString() + "px";
        this.inResponsiveEditingMode = false;
        break;
      case Resolutions.Mobile:
        this.inResponsiveEditingMode = true;
        newRes = this.getPixelWidthResolution(this.targetResolution);
        this.dashboardWidth = newRes.toString() + "px"; // ingen meny / margin öht!
        break;
      case Resolutions.HD:
        this.inResponsiveEditingMode = true;
        newRes = this.getPixelWidthResolution(this.targetResolution) - MarginPX;
        this.dashboardWidth = newRes.toString() + "px";
        break;
      case Resolutions.QHD:
        this.inResponsiveEditingMode = true;
        newRes = this.getPixelWidthResolution(this.targetResolution) - MarginPX;
        this.dashboardWidth = newRes.toString() + "px";
        break;
      case Resolutions.UHD:
        this.inResponsiveEditingMode = true;
        newRes = this.getPixelWidthResolution(this.targetResolution);
        this.dashboardWidth = newRes.toString() + "px";
        break;
      case Resolutions.IPad:
        this.inResponsiveEditingMode = true;
        newRes = this.getPixelWidthResolution(this.targetResolution) - MarginTablet; // Detta läget får inte ha utfälld meny
        this.dashboardWidth = newRes.toString() + "px";
        break;
    }


    if (oldResolution != this.dashboardWidth) {
      this.dashboardStyle["margin-left"] = "auto";
      this.dashboardStyle["margin-right"] = "auto";
      //this.dashboardStyle["box-shadow"] = "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)";
      this.dashboardStyle["width"] = this.dashboardWidth;

      if (wasInResponsive) {
        this.saveResponsiveWidgets();
      }
      else if (this.inResponsiveEditingMode) {
        this.originalDashboardConfig = TypedJSON.parse(TypedJSON.stringify(this.dashboardConfig, DashboardConfig), DashboardConfig);
      }
      if (!this.inResponsiveEditingMode) {
        this.relinkWidgetsToOriginal();
      }

      if (this.inResponsiveEditingMode) {
        if (this.dashboardConfig.ResponsiveWidgets.has(newRes)) {
          this.logger.info("i have responsive widgets..");
          this.applyResponsiveWidgets(newRes);
        }
        else {
          this.logger.info("i do not have responsive widgets..");
          let respWidgets = new ReponsiveiveOverridableWidgetConfigs();
          respWidgets.Resolution = newRes;
          this.dashboardConfig.ResponsiveWidgets.set(newRes, respWidgets);
          this.applyResponsiveWidgets(newRes);
        }
      }

      setTimeout(() => {
        this.refresh();
        //this.gridoptions.api?.resize();
      });
    }
  }

  async saveEditWidth(): Promise<void> {
    //this.logger.info("Saving drag end width now: " + this.editWidth.toString());
    localStorage.setItem("xprojector-editwidth", this.editWidth.toString());
  }



  async ngOnInit(): Promise<void> {
    this.gridoptions.itemChangeCallback = this.itemChange.bind(this);
    this.gridoptions.itemResizeCallback = this.itemResize.bind(this);
    this.gridoptions.itemInitCallback = this.itemInit.bind(this);
    this.gridoptions.itemRemovedCallback = this.itemRemoved.bind(this);
    this.gridoptions.itemValidateCallback = this.itemValidate.bind(this);
    this.gridoptions.initCallback = this.gridInit.bind(this);
    this.gridoptions.emptyCellDropCallback = this.emptyCellDrop.bind(this);
    //this.gridoptions.gridSizeChangedCallback = this.gridSizeChanged.bind(this);

    this.loadFromService = !this.dashboardConfig;

    await this.reload();

    this.setGridOptions();

    //this.sortWidgetConfigs();

    // Moved this to a specific action / stefan
    // this.savetimersource.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (event) => {
    //   if (this.modified && this.editMode) {
    //     await this.dashboardService.saveDashboard(this.dashboardConfig);
    //     this.modified = false;
    //   }
    // });


    this.widgetService.linkedWidgetSelectSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (event: LinkedWidgetSelectParameters) => {
      this.onWidgetValueSelected?.next(event.Clone());
    });

    this.widgetService.linkedWidgetChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (event: LinkedWidgetChangeParameters) => {
      this.onWidgetValueChanged?.next(event.Clone());
    });

    this.dashboardInteractService.getWidgetTypesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async ({ dashboardId, callback }) => {
      if (dashboardId == this.dashboardId) {
        callback(this.widgetTypes);
      }
    });

    this.dashboardInteractService.addWidgetSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async ({ dashboardId, widgetType, callback }) => {
      if (dashboardId == this.dashboardId) {
        await this.addWidget(widgetType.id)
        callback(true);
      }
    });

    this.dashboardInteractService.showDashboardSettingsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async ({ dashboardId, showSettings, settingsIconVisible }) => {
      //this.logger.info('showSettings', showSettings);
      //this.logger.info('settingsIconVisible', settingsIconVisible);
      if (!dashboardId || dashboardId == this.dashboardId) {
        if (showSettings != undefined) {
          this.showSettings = showSettings;
        }
        if (settingsIconVisible != undefined) {
          this.showDashboardSettings = settingsIconVisible;
        }
      }
    });

    this.dashboardInteractService.widgetOutputChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (events: DashboardOutputChangeParameters[]) => {
      events.forEach(e => {
        let param = this.dashboardOutputParameters.find(p => p.outputParameterName == e.outputParameterName);
        if (param) {
          param.value = e.value;
        }
        else {
          this.dashboardOutputParameters.push(e.Clone());
        }
      });

      await this.updateParameters(this.dashboardOutputParameters);
    });

    this.xprojClient.authenticatedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async () => {
      if (this.subscriptionsEnabled) {
        await this.SubscribeAllProjecionData();
      }
    });

    this.xprojClient.projectionDataChangedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (projectionId) => {
      if (this.subscriptionsEnabled && this.dashboardConfig) {
        if (this.dashboardConfig.GlobalWidgetSettings.MinUpdateInterval == 0) {
          this.projectionIdsChanged.push(projectionId);
          this.emitOnDataChange();
        }
        else {
          this.dataHasChanged = true;
          if (this.projectionIdsChanged.findIndex(p => p == projectionId) < 0) {
            this.projectionIdsChanged.push(projectionId);
          }

          if (!this.dataChangedTimerSource) {
            this.dataChangedTimerSource = timer(this.dashboardConfig.GlobalWidgetSettings.MinUpdateInterval, this.dashboardConfig.GlobalWidgetSettings.MinUpdateInterval);
            this.dataChangedTimerSource.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (event) => {
              if (this.dataHasChanged) {
                this.emitOnDataChange();
              }
            });
          }
        }
      }
    });

    this.dashboardInteractService.dashboardRefresh.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async () => {
      this.refresh();
    });

    this.dashboardInteractService.dashboardReset.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async () => {
      this.widgetService.reset();
    });

    this.SubscribeAllProjecionData();

    this.setupSubscribeOnParameters();

    this.initScriptedParameter();
  }

  setupSubscribeOnParameters(): void {
    if (this.hasSetupSubscribeOnParameters)
      return;

    this.hasSetupSubscribeOnParameters = true;

    this.widgetService.widgetOutputChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((parameters) => {
      let now = new Date();
      this.logger.info("parameter has changed?", parameters);
      for (let p of parameters) {
        if (!this.parameterLastChanged[p.outputParameterId]) {
          let nParam = new WidgetOutputParameter();
          nParam.datatype = p.datatype;
          nParam.id = p.outputParameterId;
          nParam.name = this.getOutputParameterName(p.widgetId, p.outputParameterId);
          this.allParameters.push(nParam);
        }
        this.parameterLastChanged[p.outputParameterId] = now;
      }

      this.RunDynamicLinkedPrametersIfNeeded();
    });
  }

  getOutputParameterName(widgetId: string, parameterId: string): string {
    let widget = this.dashboardConfig?.WidgetConfigs.find(w => w.Id == widgetId);
    if (widget) {
      let outParam = widget.OutputParameters.find(p => p.id == parameterId);
      if (outParam) {
        return outParam.name;
      }
    }

    return parameterId;
  }

  selectedLinkedParmaeterTriggerOnChanged(event: any): void {
    let selectedparam = this.selectedLinkedParameter as ScriptedParametersOnParameterChange;
    selectedparam.OnParameterChanged.length = 0;
    for (let it of this.selectedLinkedParmaeterTriggerOn) {
      let sparam = it as WidgetOutputParameter;
      selectedparam.OnParameterChanged.push(sparam.id);
    }
  }

  selectedLinkedParameterChanged(event: any): void {

    //selectedLinkedParmaeterTriggerOn
    let selectedparam = this.selectedLinkedParameter as ScriptedParametersOnParameterChange;
    this.selectedLinkedParmaeterTriggerOn.length = 0;
    for (let it of selectedparam.OnParameterChanged) {
      for (let jit of this.allParameters) {
        if (it == jit.id) {
          this.selectedLinkedParmaeterTriggerOn.push(jit);
        }
      }
    }
  }

  private RunDynamicLinkedPrametersIfNeeded(): void {
    let now = new Date();
    let heldBackTreshold = now;
    heldBackTreshold.setMilliseconds(now.getMilliseconds() - 50);

    for (let sp of this.dashboardConfig.DynamicParameters) {
      let splinked = sp as ScriptedParametersOnParameterChange;
      if (!splinked ||
        !splinked.OnParameterChanged ||
        splinked.OnParameterChanged.length == 0)
        continue;


      if (splinked.lastran > heldBackTreshold) {
        splinked.heldBack = true;
        continue;
      }
      let shouldRun = false;

      if (!splinked.heldBack) {
        for (let p of splinked.OnParameterChanged) {
          let pchanged = this.parameterLastChanged[p];
          if (!pchanged || pchanged <= splinked.lastran) {
            continue;
          }
          shouldRun = true;
          break;
        }
      }
      if (shouldRun) {
        splinked.lastran = now;
        this.lua.RunScriptedParameter(splinked);
      }
    }
  }

  async updateAndUpsertDynamicParameters(events: DashboardOutputChangeParameters[], source: ScriptedParameters) {
    let outputs: WidgetOutputChangeParameters[] = [];

    await ArrayUtils.AsyncForEach(events, async (event: DashboardOutputChangeParameters) => {
      let output = this.dashboardConfig?.OutputParameters.find(out => out.name == event.outputParameterName);
      if (!output) {
        output = new WidgetOutputParameter();
        output.name = event.outputParameterName;
        output.datatype = event.datatype;
        output.id = "dynamic-" + source.Name + "-" + output.name;
        this.dashboardConfig.OutputParameters.push(output);
      }
      if (output) {
        let outputChanged = new WidgetOutputChangeParameters();
        outputChanged.widgetId = '__dashboard__';
        outputChanged.outputParameterId = output.id;
        outputChanged.value = event.value;
        outputChanged.datatype = output.datatype;

        outputs.push(outputChanged);
      }
    });
    if (outputs.length > 0) {
      this.widgetService.outputParametersChanged(outputs, true);
    }
  }

   isTouchDevice()  : boolean
   {
    return navigator.maxTouchPoints > 0;
    /*
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));*/
  }


  ngAfterViewInit(): void {
    this.logger.info("After view init and targetresolution is: ", this.targetResolution);
    this.refreshResolution();
    this.widgetService.refresh();
    this.IsTouch = this.isTouchDevice();

    if(this.IsTouch)
    {
      //this.grid.nativeElement.style["border-left"]="10px solid red !important;";
      //this.grid.nativeElement.style["background-color"]="red !important;";
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    this.refreshDashboardTimerSource$?.unsubscribe();
    this.refreshDashboardTimerSubscription?.unsubscribe();
    //this.savetimersubscription?.unsubscribe();
    this.UnsubscribeAllProjecionData();
  }

  insertSystemOutputParameters() {
    let updated: boolean = false;

    let output = this.dashboardConfig?.OutputParameters.find(out => out.id == CUSTOMERID_OUTPUTPARAMETERID);
    if (!output) {
      output = new WidgetOutputParameter();
      output.id = CUSTOMERID_OUTPUTPARAMETERID;
      output.name = 'customerid';
      output.datatype = OutputDataType.String;
      this.dashboardConfig.OutputParameters.push(output);

      updated = true;
    }

    // let outputRemotNodeId = this.dashboardConfig?.OutputParameters.find(out => out.id == REMOTENODEID_OUTPUTPARAMETERID);
    // if (!outputRemotNodeId) {
    //   outputRemotNodeId = new WidgetOutputParameter();
    //   outputRemotNodeId.id = REMOTENODEID_OUTPUTPARAMETERID;
    //   outputRemotNodeId.name = 'remotenodeid';
    //   outputRemotNodeId.datatype = OutputDataType.String;
    //   this.dashboardConfig.OutputParameters.push(outputRemotNodeId);

    //   updated = true;
    // }

    // if (updated) {
    //   this.dashboardService.saveDashboard(this.dashboardConfig);
    // }
  }

  async updateParameters(events: DashboardOutputChangeParameters[]) {
    let outputs: WidgetOutputChangeParameters[] = [];
    await ArrayUtils.AsyncForEach(events, async (event: DashboardOutputChangeParameters) => {
      let output = this.dashboardConfig?.OutputParameters.find(out => out.name.toLowerCase() == event.outputParameterName.toLowerCase());
      if (output) {
        let outputChanged = new WidgetOutputChangeParameters();
        outputChanged.widgetId = '__dashboard__';
        outputChanged.outputParameterId = output.id;
        outputChanged.value = event.value;
        outputChanged.datatype = output.datatype;

        outputs.push(outputChanged);

        //this.logger.info('updateParameters', outputChanged);
      }
    });
    if (outputs.length > 0) {
      this.outputParametetersUpdated = true;
      this.widgetService.outputParametersChanged(outputs, true);
    }
  }

  async reload() {

    this.outputParametetersUpdated = false;
    this.markedWidgetConfig = undefined;
    this.copiedWidgetConfig = undefined;
    this.initDone = false;

    if (this.loadFromService && this.dashboardId?.length > 0) {
      try {
        if (this.assetDashboard) {
          const serializer = new TypedJSON(DashboardConfig);
          let response = await fetch('./assets/dashboards/' + this.dashboardId);
          if (response.ok) {
            let json = await response.text();
            if (json?.length > 0) {
              this.dashboardConfig = serializer.parse(json);
            }
          }
          else {
            this.dashboardConfig = new DashboardConfig();
            this.dashboardConfig.Id = this.dashboardId;
          }
        }
        else {
          this.dashboardConfig = await this.dashboardService.loadDashboard(this.dashboardId, this.dashboardVersion, this.dashboardTag, this.systemDashboard);
        }
      }
      catch (error) {
        this.logger.error('error', error);
        this.dashboardConfig = new DashboardConfig();
        this.dashboardConfig.Id = this.dashboardId;
      }
    }

    if (this.dashboardConfig) {
      if (!this.dashboardConfig.ResponsiveWidgets) {
        this.dashboardConfig.ResponsiveWidgets = new Map<Number, ReponsiveiveOverridableWidgetConfigs>();
      }

      this.subscriptionsEnabled = this.enableSubscriptions && this.dashboardConfig.SubscriptionsEnabled;

      this.originalDashboardConfig = TypedJSON.parse(TypedJSON.stringify(this.dashboardConfig, DashboardConfig), DashboardConfig);

      if (this.dashboardConfig.Grid.ShowSideNav) {
        setTimeout(() => {
          this.toogleNav(true);
        });
      }

      this.sortWidgetConfigs();
      this.setGridOptions();
      this.gridoptions.api?.optionsChanged();
      this.modified = false;

      this.insertSystemOutputParameters();

      this.updateRefreshTimer();
    }
    // if (this.isMobile) {
    //   this.gridoptions.mobileBreakpoint = 0;
    // }

    if (!this.editMode)
      this.refreshResolutionView();
    else {
      this.targetResolution = Resolutions.Sandbox;
      this.refreshResolution();
    }

    //let userstyle = this.xprojuserstyles.nativeElement as Element

    let xprojuserstyles = document.getElementById("xprojuserstyles");
    if (xprojuserstyles && this.dashboardConfig.UserCSS && this.dashboardConfig.UserCSS != "") {
      console.log("setting xprojcssstyles = ", this.dashboardConfig.UserCSS);
      xprojuserstyles.textContent = this.dashboardConfig.UserCSS;
    }
  }

  updateRefreshTimer() {
    if (this.dashboardConfig.RefreshEnabled) {
      if (!this.refreshDashboardTimerSource$) {
        this.refreshDashboardTimerSource$ = new BehaviorSubject(this.dashboardConfig.RefreshInterval);

        this.refreshDashboardTimerSubscription = this.refreshDashboardTimerSource$.pipe(switchMap(val => interval(val).pipe(map((x) => {
          if (this.dashboardConfig?.RefreshEnabled) {
            this.widgetService?.projectionDataChanged([]);
          }
        })))).subscribe();
      }
      else {
        this.refreshDashboardTimerSource$.next(this.dashboardConfig.RefreshInterval);
      }
    }
  }

  async setDashboardId(id: string, version: number = -1, tag: string = '', systemDashboard: boolean = false, parameters: DashboardOutputChangeParameters[] = []) {
    if (id != this.dashboardId || this.dashboardVersion != version || this.systemDashboard != systemDashboard) {
      this.selectedWidgetConfig = null;
      this.UnsubscribeAllProjecionData();
      this.dashboardId = id;
      this.dashboardVersion = version;
      this.dashboardTag = tag;
      this.systemDashboard = systemDashboard;
      this.oldActualTargetRes = 0;
      if (parameters.length > 0) {
        this.dashboardOutputParameters = parameters;
      }
      await this.reload();

      this.SubscribeAllProjecionData();
      this.initScriptedParameter();
      //this.sortWidgetConfigs();
    }
    else if (this.outputParametetersUpdated) {
      await this.reload();
    }
  }

  initScriptedParameter() {
    if (this.dashboardConfig) {
      for (let it of this.dashboardConfig.DynamicParameters) {
        let itEvent = it as ScriptedParametersOnEvent;
        if (!itEvent) {
          this.setupSubscribeOnParameters();
          continue;
        }
        if (itEvent.OnEvent == DashboardEvent.INIT || itEvent.OnEvent == DashboardEvent.PARAMETERS_LOADED) {
          this.lua.RunScriptedParameter(itEvent);
        }
      }
    }
  }

  async setDashboardOutputParameters(parameters: DashboardOutputChangeParameters[]) {
    this.dashboardOutputParameters = parameters;
    await this.updateParameters(this.dashboardOutputParameters);
  }

  refresh(): void {
    this.gridoptions.api?.optionsChanged();
  }

  refreshToolbox(): void {

    this.logger.info("Setting grid native element contenteditable to ", this.enablePasteInGrid);
    this.grid.nativeElement.contenteditable = this.enablePasteInGrid;

    let sidenavs = [];
    this.sidenavConfigs.forEach(c => {
      sidenavs.push(c.Id);
    });
    this.dashboardConfig.Grid.SideNavWidgets = sidenavs;

    this.setGridOptions();
    this.refresh();
  }

  setInitDone(done: boolean) {
    if (done && !this.initDone) {
      this.initDone = true;
      this.logger.debug('Init ddashboard done!');
    }
  }

  DashboardEvent = DashboardEvent;
  editorOptionsLua = { theme: environment.editortheme, language: 'lua', automaticLayout: true, acceptSuggestionOnEnter: "smart", minimap: { enabled: false } };
  public selectedEventBasedParameter: any = null;
  public selectedLinkedParameter: any = null;

  ShowLoadParameters(): void {
    this.showLoadParameters = true;
  }

  AddLoadParameter(): void {
    let newParam = new ScriptedParametersOnEvent();
    newParam.Description = "New script";
    newParam.OnEvent = DashboardEvent.PARAMETERS_LOADED;
    this.dashboardConfig.DynamicParameters.push(newParam);
  }

  ShowLinkedParameters(): void {
    this.showLinkedParameters = true;
  }

  AddLinkedParameter(): void {
    let newParam = new ScriptedParametersOnParameterChange();
    newParam.Description = "New script";
    this.dashboardConfig.DynamicParameters.push(newParam);
  }

  RemoveScriptedParameter(param: ScriptedParametersOnEvent): void {
    for (let i = 0; i < this.dashboardConfig.DynamicParameters.length; i++) {
      if (this.dashboardConfig.DynamicParameters[i] == param) {
        this.dashboardConfig.DynamicParameters.splice(i, 1);
        return;
      }
    }
    // todo
    //this.dashboardConfig.DynamicParameters.
  }


  HideLoadParameters(): void {
    this.showLoadParameters = false;
  }


  ShowLinkedPrameters(): void {
    this.showLoadParameters = false;
    this.showLinkedParameters = true;
  }


  HideLinkedParameters(): void {
    this.showLinkedParameters = false;
  }

  savingDashboard: ClrLoadingState = ClrLoadingState.DEFAULT;

  async save(forceSave: boolean = false): Promise<boolean> {

    if (this.inResponsiveEditingMode) {
      this.logger.info("iam saving in responsive edit mode");
    }

    let oldIsinResponsiveMode = this.inResponsiveEditingMode;
    let oldRes = this.targetResolution;

    if (this.inResponsiveEditingMode) {
      this.targetResolution = Resolutions.Sandbox;
      this.refreshResolution();
    }

    if ((this.modified || forceSave) && this.editMode) {
      this.savingDashboard = ClrLoadingState.LOADING;
      // if (this.isMobile && !this.dashboardConfig.Id.endsWith('-mobile')) {
      //   this.dashboardConfig.Id = this.dashboardConfig.Id + '-mobile';
      // }
      // TODO: Make sure this.dashboardConfig is a link to the source + make a new one that may be resolution based
      this.logger.info("saving.. responsivewidgets: ", this.dashboardConfig.ResponsiveWidgets);
      if (this.beforeSave) {
        this.dashboardConfig = this.beforeSave(this.dashboardConfig);
      }
      let success = await this.dashboardService.saveDashboard(this.dashboardConfig);

      if (oldIsinResponsiveMode) {
        this.targetResolution = oldRes;
        this.refreshResolution();
      }

      if (success) {
        this.modified = false;
        this.savingDashboard = ClrLoadingState.SUCCESS;
        return true;
      }
      else {
        this.savingDashboard = ClrLoadingState.ERROR;
        return false;
      }
    }
  }

  async onViewWidgetClick(widgetConfig: WidgetConfig) {
    this.selectedWidgetConfig = widgetConfig;
    this.viewFullScreen = true;

    widgetConfig.Height = this.mainview.nativeElement.offsetHeight;
    setTimeout(() => {
      this.widgetService.refresh(this.selectedWidgetConfig.Id);
    });
  }

  async onExportWidgetClick(widgetConfig: WidgetConfig, exportAs: string) {
    if (exportAs == 'queries') {
      this.widgetService.exportQueries(widgetConfig.Id, (queries, excludeColumns) => {
        if (queries?.length > 0) {
          let params: WidgetExportParameters = new WidgetExportParameters();
          params.name = widgetConfig.DisplayTitle?.length > 0 ? widgetConfig.DisplayTitle : widgetConfig.Name;
          params.queries = queries;
          params.decimals = this.dashboardConfig.GlobalWidgetSettings.Decimals;
          params.excludeColumns = excludeColumns;
          this.onWidgetExport?.emit(params);
        }
        else {
          this.widgetService.exportData(widgetConfig.Id, (data) => {
            let params: WidgetExportParameters = new WidgetExportParameters();
            params.name = widgetConfig.DisplayTitle?.length > 0 ? widgetConfig.DisplayTitle : widgetConfig.Name;
            params.data = data;
            params.decimals = this.dashboardConfig.GlobalWidgetSettings.Decimals;
            params.excludeColumns = excludeColumns;
            this.onWidgetExport?.emit(params);
          });
        }
      });
    }
    else if (exportAs == 'image') {
      this.widgetService.exportImage(widgetConfig.Id, (href) => {
        widgetConfig.Href = href;
        let link: any = document.getElementById('download-image');
        link.href = href;
        setTimeout(() => {
          link.click();
        });
      });

    }
  }

  exportDashboard() {
    let json: string = TypedJSON.stringify(this.dashboardConfig, DashboardConfig);
    var blob = new Blob([json], { type: "text/plain;charset=utf-8" });
    saveAs(blob, this.exportFilename);
  }

  async onMarkWidgetClick(widgetConfig: WidgetConfig) {
    if (widgetConfig == this.markedWidgetConfig) {
      this.markedWidgetConfig = undefined;
    }
    else {
      this.markedWidgetConfig = widgetConfig;
    }
    this.copiedWidgetConfig = undefined;
  }


  async onCopyWidget(event: ClipboardEvent, widgetConfig: WidgetConfig) {
    this.logger.info("Received a clip board copy event");
    event.preventDefault();
    let newCopyInstance = new CopiedWidget();
    newCopyInstance.CopiedWidget = widgetConfig;
    let json = TypedJSON.stringify(newCopyInstance, CopiedWidget);
    this.clipboard.copy(json);
    this.logger.info("Returning from copy clipboard event");
    // really couldnt get this to work so i made a hack in the paste replace instead
    // var savedTabIndex = this.grid.nativeElement.getAttribute('tabindex')
    // this.grid.nativeElement.setAttribute('tabindex', '-1')
    // this.grid.nativeElement.focus()
    // this.grid.nativeElement.setAttribute('tabindex', savedTabIndex)
  }

  async onPasteWidgetReplace(event: ClipboardEvent, widgetConfig: WidgetConfig) {
    try {
      this.logger.info("Received a clipboard paste event - replace");
      let json = event.clipboardData.getData("text");
      event.preventDefault();
      let newConfigCopyinstance = TypedJSON.parse(json, CopiedWidget);
      let newConfig = newConfigCopyinstance.CopiedWidget;

      // This is a hack but i couldnt set focus on the gridster component
      // so instead i check if the of pasted item is the same as copied
      // if so i assume a new insert
      if (newConfig.Id == widgetConfig.Id) {
        let testInstance = new CopiedWidget();
        testInstance.CopiedWidget = widgetConfig;
        let testjson = TypedJSON.stringify(testInstance, CopiedWidget);

        if (testjson == json) {
          let newClonedConfig = newConfig.Clone();
          newClonedConfig.Id = Guid.newGuid();
          this.gridWidgetConfigs.push(newClonedConfig);
          this.logger.info("Returning from paste clipboard event");
          this.refresh();
          return;
        }
      }

      newConfig.Id = widgetConfig.Id;
      newConfig.cols = widgetConfig.cols;
      newConfig.rows = widgetConfig.rows;
      newConfig.x = widgetConfig.x;
      newConfig.y = widgetConfig.y;

      for (let i = 0; i < this.gridWidgetConfigs.length; i++) {
        let t = this.gridWidgetConfigs[i];
        if (t.Id == widgetConfig.Id) {
          this.logger.info("Replacing widget in gridWidgetConfigs");
          this.gridWidgetConfigs[i] = newConfig;
        }
      }
      this.logger.info("Returning from clipboard event");
      this.refresh();
    }
    catch
    {
      this.logger.info("Warning: Pasting failed");
    }
  }


  async onPasteWidgetInsert(event: ClipboardEvent) {
    try {
      this.logger.info("Received a clip board paste event - insert");
      let json = event.clipboardData.getData("text");
      event.preventDefault();
      let newConfigCopyinstance = TypedJSON.parse(json, CopiedWidget);
      let newConfig = newConfigCopyinstance.CopiedWidget;
      let newClonedConfig = newConfig.Clone();
      newClonedConfig.Id = Guid.newGuid();
      this.gridWidgetConfigs.push(newClonedConfig);
      this.logger.info("Returning from clip board event");
      this.refresh();
    }
    catch
    {
      this.logger.info("Warning: Pasting failed");
    }
  }


  async onEditWidgetClick(widgetConfig: WidgetConfig) {
    if (this.selectedWidgetConfig == widgetConfig) {
      this.selectedWidgetConfig = null;
    }
    else {
      this.editFullScreen = this.lastEditFullScreen;
      this.selectedWidgetConfig = widgetConfig;

      setTimeout(() => {
        if (this.selectedWidgetConfig) {
          this.widgetService.refresh(this.selectedWidgetConfig.Id);
        }
      });

      this.refresh();
    }

  }

  async setSubscriptionEnabled(enabled: boolean) {
    if (this.subscriptionsEnabled != enabled) {
      if (!this.subscriptionsEnabled) {
        await this.SubscribeAllProjecionData();
      }
      else {
        await this.UnsubscribeAllProjecionData();
      }

      this.subscriptionsEnabled = enabled;
    }
  }

  private async emitOnDataChange() {
    if (this.dashboardConfig) {
      this.dataHasChanged = false;
      this.widgetService.projectionDataChanged(this.projectionIdsChanged);
      this.projectionIdsChanged = [];
    }
  }

  async SubscribeAllProjecionData() {
    if (this.dashboardConfig && this.subscriptionsEnabled) {
      await ArrayUtils.AsyncForEach(this.dashboardConfig.GetSubscriprionData(), async (data: { projectionId: string, group: string[] }) => {
        try {
          await this.xprojClient.RequestSubscribeProjectionData(data.projectionId, data.group);
        }
        catch { }
      });
    }
  }

  async UnsubscribeAllProjecionData() {
    if (this.dashboardConfig) {
      await ArrayUtils.AsyncForEach(this.dashboardConfig.GetSubscriprionData(), async (data: { projectionId: string, group: string[] }) => {
        try {
          await this.xprojClient.RequestUnsubscribeProjectionData(data.projectionId, data.group);
        }
        catch { }
      });
    }
  }

  async SaveWidgetConfig() {
    this.widgetService.saveConfig(this.selectedWidgetConfig.Id, async (config) => {
      if (config.Title && config.Title.length > 0)
        config.DisplayTitle = config.Title;

      let linkedwidget: any = this.dashboardConfig.WidgetConfigs?.find(w => w.Id == this.selectedWidgetConfig.LinkedInputWidget);
      this.selectedWidgetConfig.ControlledByMaster = linkedwidget?.constructor.name == 'MasterWidgetConfig';

      //await this.dashboardService.saveDashboard(this.dashboardConfig);
      this.widgetService.updateQuery(this.selectedWidgetConfig.Id, this.selectedWidgetConfig);
    });
  }

  async removeWidgetConfig() {
    if (this.selectedWidgetConfig) {
      this.modalService.ShowConfirmModal({ header: 'Remove widget', description: 'Remove widget, are you sure?' }, async (result) => {
        if (result) {
          this.dashboardConfig.WidgetConfigs = this.dashboardConfig.WidgetConfigs.filter(c => c != this.selectedWidgetConfig);
          this.sortWidgetConfigs();
          //this.save(); // No, we should not save here. It just annoying
          this.selectedWidgetConfig = null;
          this.markedWidgetConfig = undefined;
          this.copiedWidgetConfig = undefined;
        }
      });
    }
  }

  async onSettingsClose($event) {
    this.showSettings = false;
    this.viewFullScreen = false;
    this.selectedWidgetConfig = null;

    this.sortWidgetConfigs();
    this.setGridOptions();

    this.updateRefreshTimer();

    if (this.editMode) {
      // await this.dashboardService.saveDashboard(this.dashboardConfig); No we should not save it here
    }

    setTimeout(() => {
      this.toogleNav(!this.sidenavCollapsed);
    });
  }

  async onEditClose($event) {
    let area = this.getEditArea();
    if (!this.editFullScreen && area) {
      this.editWidth = area.elRef.nativeElement.clientWidth;
      this.saveEditWidth();
    }

    this.selectedWidgetConfig = null;
    this.lastEditFullScreen = this.editFullScreen;
    this.editFullScreen = false;
    this.viewFullScreen = false;

    this.sortWidgetConfigs();
    this.refresh();
  }

  async onSelectWidget(widgetConfig: WidgetConfig) {
    this.showSettings = false;
    this.sortWidgetConfigs();
    if (this.editMode) {
      // no we dont save here.
      //await this.dashboardService.saveDashboard(this.dashboardConfig);
    }
    this.selectedWidgetConfig = widgetConfig;
  }

  async addWidget(widgetTypeId: string, x?: number, y?: number, cols?: number, rows?: number) {
    let newConfig: WidgetConfig;
    switch (widgetTypeId) {
      case 'chart':
        newConfig = new ChartWidgetConfig();
        break;
      case 'table':
        newConfig = new TableWidgetConfig();
        break;
      case 'master':
        newConfig = new MasterWidgetConfig();
        break;
      case 'piechart':
        newConfig = new PiechartWidgetConfig();
        break;
      case 'barchart':
        newConfig = new PiechartWidgetConfig();
        (newConfig as PiechartWidgetConfig).ChartType = PieChartType.BARCHART;
        break;
      case 'label':
        newConfig = new LabelWidgetConfig();
        break;
      case 'spectrumanalyzer':
        newConfig = new SpectrumAnalyzerConfig();
        break;
      case 'spc':
        newConfig = new SpcConfig();
        break;
      case 'text':
        newConfig = new TextWidgetConfig();
        break;
      case 'map':
        newConfig = new MapWidgetConfig();
        break;
      case 'svg':
        newConfig = new SvgWidgetConfig();
        break;
      case 'cubical3d':
        newConfig = new Cubical3dWidgetConfig();
        break;
      case 'output':
        newConfig = new OutputControllerWidgetConfig();
        break;
      case 'projectiondataeditor':
        newConfig = new ProjectionDataEditorWidgetConfig();
        break;
      case 'container':
        newConfig = new ContainerWidgetConfig();
        break;


      default: newConfig = new ChartWidgetConfig();
    }

    let item = this.gridoptions.api?.getFirstPossiblePosition(newConfig);

    newConfig.y = y != undefined ? y : item.y;
    newConfig.x = x != undefined ? x : item.x;
    newConfig.rows = rows ? rows : 2;
    newConfig.cols = cols ? cols : 3;
    newConfig.Title = 'Widget_' + this.dashboardConfig.WidgetConfigs.length;
    newConfig.Name = 'Widget_' + this.dashboardConfig.WidgetConfigs.length;
    newConfig.layerIndex = this.dashboardConfig.Grid.DefaultLayerIndex;

    this.dashboardConfig.WidgetConfigs.push(newConfig);
    this.sortWidgetConfigs();
    this.modified = true;
    //await this.dashboardService.saveDashboard(this.dashboardConfig);
    //this.selectedWidgetConfig = newConfig;
  }

  pasteWidgetConfig(widgetConfig: WidgetConfig) {
    if (widgetConfig) {
      let copy = widgetConfig.Clone();
      copy.Id = Guid.newGuid();
      let item = this.gridoptions.api?.getFirstPossiblePosition(copy);

      if (item) {
        copy.Name = widgetConfig.Name + ' (Copy)';
        copy.x = item.x;
        copy.y = item.y;

        this.dashboardConfig.WidgetConfigs.push(copy);
        this.sortWidgetConfigs();
        this.modified = true;
      }
    }
    this.copiedWidgetConfig = undefined;
  }

  resetDashboard() {
    this.modalService.ShowConfirmModal({ header: 'Reset', description: 'Reset and clear all widgets, are you sure?' }, (result) => {
      if (result) {
        this.dashboardConfig.WidgetConfigs.length = 0;
        this.dashboardConfig.ResponsiveWidgets?.clear();
        this.originalDashboardConfig.WidgetConfigs = [];
        this.sortWidgetConfigs();
        this.modified = true;
        this.refresh();
      }
    });
  }

  insertDragStart($event, widgetTypeId: string) {
    $event.dataTransfer.setData("widgetTypeId", widgetTypeId);
  }

  setGridOptions() {
    if (this.dashboardConfig) {
      this.gridBackgroundColor = this.dashboardConfig.Grid.BackgroundColor;

      this.gridoptions.gridType = this.dashboardConfig.Grid.GridType;
      this.gridoptions.compactType = this.dashboardConfig.Grid.CompactType;
      this.gridoptions.margin = this.dashboardConfig.Grid.Margin;
      this.gridoptions.minCols = this.dashboardConfig.Grid.MinCols;
      this.gridoptions.maxCols = this.dashboardConfig.Grid.MaxCols;
      this.gridoptions.minRows = this.dashboardConfig.Grid.MinRows;
      this.gridoptions.maxRows = this.dashboardConfig.Grid.MaxRows;
      this.gridoptions.fixedColWidth = this.dashboardConfig.Grid.FixedColWidth;
      this.gridoptions.fixedRowHeight = this.dashboardConfig.Grid.FixedRowHeight;
      this.gridoptions.scrollSensitivity = this.dashboardConfig.Grid.ScrollSensitivity;
      this.gridoptions.scrollSpeed = this.dashboardConfig.Grid.ScrollSpeed;

      this.gridoptions.pushDirections.north = this.dashboardConfig.Grid.PushNorth;
      this.gridoptions.pushDirections.east = this.dashboardConfig.Grid.PushEast;
      this.gridoptions.pushDirections.south = this.dashboardConfig.Grid.PushSouth;
      this.gridoptions.pushDirections.west = this.dashboardConfig.Grid.PushWest;

      this.gridoptions.disableWindowResize = this.dashboardConfig.Grid.DisableWindowResize;

      this.gridoptions.allowMultiLayer = this.dashboardConfig.Grid.AllowMultiLayer;
      this.gridoptions.defaultLayerIndex = this.dashboardConfig.Grid.DefaultLayerIndex;
      this.gridoptions.maxLayerIndex = this.dashboardConfig.Grid.MaxLayerIndex;
      this.gridoptions.baseLayerIndex = this.dashboardConfig.Grid.BaseLayerIndex;

      this.gridoptions.resizable.enabled = this.editMode;
      this.gridoptions.draggable.enabled = this.editMode;
    }
  }

  setGridItemStyle(config: WidgetConfig): string {
    let background = config.BackgroundColor;
    if (background == '')
      background = 'transparent';
    return `background: ${background}; border: ${config.BorderWidth}px solid ${config.BorderColor}; border-radius: ${config.BorderRadius}px`;
  }

  sortWidgetConfigs(): void {
    let configs = [];
    this.widgetInitCounter = this.dashboardConfig.WidgetConfigs.length;
    this.dashboardConfig.WidgetConfigs = this.dashboardConfig.WidgetConfigs.sort((a, b) => {
      if (a.constructor.name != 'MasterWidgetConfig') {
        return -1;
      }

      return 1;
    })

    if (this.dashboardConfig.Grid.ShowSideNav) {
      this.gridWidgetConfigs = this.dashboardConfig.WidgetConfigs.filter(c => !this.dashboardConfig.Grid.SideNavWidgets.find(w => c.Id == w));

      let sidenavs = [];
      this.dashboardConfig.Grid.SideNavWidgets.forEach(id => {
        let widget = this.dashboardConfig.WidgetConfigs.find(c => id == c.Id)
        if (widget) {
          sidenavs.push(widget);
        }
      })
      this.sidenavConfigs = sidenavs;

    }
    else {
      this.gridWidgetConfigs = this.dashboardConfig.WidgetConfigs;
      this.sidenavConfigs = [];
    }


    // this.logger.info("sorting widgets for mobile mode");
    this.gridWidgetConfigs = this.gridWidgetConfigs.sort(
      (w1: WidgetConfig, w2: WidgetConfig) => {
        return (w1.x + w1.y * this.dashboardConfig.Grid.MaxCols) - (w2.x + w2.y * this.dashboardConfig.Grid.MaxCols)
      }
    )

    this.initCounter = this.gridWidgetConfigs.length;
    //this.logger.info('WidgetConfigs', this.dashboardConfig.WidgetConfigs);
  }

  onBeforeWidgetInit(widgetConfig: WidgetConfig) {
  }

  onBeforeWidgetInitSidenav(widgetConfig: WidgetConfig) {
    if (widgetConfig.rows > 0) {
      let height = this.mainview.nativeElement.offsetHeight - 24;
      widgetConfig.Height = height * (widgetConfig.rows / 12);
    }
  }


  onAfterWidgetInit(widgetConfig: WidgetConfig) {
    this.widgetInitCounter--;
    if (this.widgetInitCounter == 0) {
      setTimeout(() => {
        this.onAllWidgetInitiated();
      });
    }
  }

  onAllWidgetInitiated() {
    this.logger.debug('onAllWidgetInitiated');
    this.updateParameters(this.dashboardOutputParameters);
    this.widgetService.initDone();
  }

  onConfigChanged($event) {
    this.sortWidgetConfigs();
  }

  onResize(): void {
    this.logger.info('onresizeee');
  }

  emptyCellDrop($event, item: GridsterItem): void {
    // tslint:disable-next-line:no-console
    var widgetTypeId = $event.dataTransfer.getData("widgetTypeId");
    console.info('empty cell drop', $event, item, widgetTypeId);
    this.addWidget(widgetTypeId, item.x, item.y, item.cols, item.rows);
  }

  itemChange(item: GridsterItem, itemComponent: GridsterItemComponentInterface): void {
    // // tslint:disable-next-line:no-console
    // console.info('itemChanged', item, itemComponent);
    this.modified = true;
  }

  itemResize(item: GridsterItem, itemComponent: GridsterItemComponentInterface): void {
    // // tslint:disable-next-line:no-console
    // console.info('itemResized', item, itemComponent, this.initDone);
    if (this.initDone) {
      setTimeout(() => {
        this.widgetService.resized(item, itemComponent);
      });
      this.modified = true;
    }
    else {
      let config = item as WidgetConfig;

      let row: any = itemComponent.el.firstChild;
      config.Width = itemComponent.width;
      config.Height = itemComponent.height - row.offsetHeight;
    }
  }

  itemInit(item: GridsterItem, itemComponent: GridsterItemComponentInterface): void {
    let config = item as WidgetConfig;
    let height = itemComponent?.gridster.curRowHeight;
    let row: any = itemComponent.el.firstChild;
    config.Height = height * item.rows - (row.offsetHeight < 24 ? 24 : row.offsetHeight);

    this.initCounter--;
    this.logger.debug('initCounter', this.initCounter);
    if (this.initCounter == 0) {
      setTimeout(() => {
        this.setInitDone(true);
      });
    }
    // // tslint:disable-next-line:no-console
    // console.info('itemInitialized', item, itemComponent);
  }

  itemRemoved(item: GridsterItem, itemComponent: GridsterItemComponentInterface): void {
    // // tslint:disable-next-line:no-console
    // console.info('itemRemoved', item, itemComponent);
  }

  itemValidate(item: GridsterItem): boolean {
    return item.cols > 0 && item.rows > 0;
  }

  gridInit(grid: GridsterComponentInterface): void {
    // // tslint:disable-next-line:no-console
    console.info('gridInit', grid);
    this.initCounter = this.gridWidgetConfigs.length;
    this.logger.debug('gridInit initCounter', this.initCounter);
    this.setInitDone(this.initCounter == 0);
  }

  gridDestroy(grid: GridsterComponentInterface): void {
    // // tslint:disable-next-line:no-console
    // console.info('gridDestroy', grid);
  }

  gridSizeChanged(grid: GridsterComponentInterface): void {
    // // tslint:disable-next-line:no-console
    // console.info('gridSizeChanged', grid);
    // if (this.editMode) {
    //   this.state.Dashboards.SaveDashboard(this.dashboardConfig);
    // }
  }

  onSplitDragEnd($event) {
    //this.saveEditWidth();
    this.refresh();
  }

  getEditArea(): SplitAreaDirective {
    let result: SplitAreaDirective = undefined;
    this.areasEl.forEach(area => {
      if (area.order == 3) {
        result = area;
      }
    });

    return result;
  }

  toogleNav(forceStateExpanded?: boolean) {
    let sidenavCollapsed: boolean = false;

    if (forceStateExpanded != undefined) {
      this.sidenavCollapsed = forceStateExpanded;
    }

    if (this.dashboardConfig.Grid.SideNavPosition == SideNavPosition.RIGHT) {
      if (this.sidenavCollapsed) {
        this.areasEl.forEach(area => {
          if (area.order == 2) {
            if (forceStateExpanded) {
              area.size = this.dashboardConfig.Grid.SideNavWidth;
            }
            area.expand();
          }
        });
        sidenavCollapsed = false;
      }
      else {
        this.areasEl.forEach(area => {
          if (area.order == 2) {
            area.collapse(25, 'left');
          }
        });
        sidenavCollapsed = true;
      }
    }
    else if (this.dashboardConfig.Grid.SideNavPosition == SideNavPosition.LEFT) {
      if (this.sidenavCollapsed) {
        this.areasEl.forEach(area => {
          if (area.order == 1) {
            if (forceStateExpanded) {
              area.size = this.dashboardConfig.Grid.SideNavWidth;
            }
            area.expand();
          }
        });
        sidenavCollapsed = false;
      }
      else {
        this.areasEl.forEach(area => {
          if (area.order == 1) {
            area.collapse(25, 'right');
          }
        });
        sidenavCollapsed = true;
      }
    }
    else if (this.dashboardConfig.Grid.SideNavPosition == SideNavPosition.TOP) {
      if (this.sidenavCollapsed) {
        this.areasEl.forEach(area => {
          if (area.order == 1) {
            if (forceStateExpanded) {
              area.size = this.dashboardConfig.Grid.SideNavWidth;
            }
            area.expand();
          }
        });
        sidenavCollapsed = false;
      }
      else {
        this.areasEl.forEach(area => {
          if (area.order == 1) {
            area.collapse(25, 'right');
          }
        });
        sidenavCollapsed = true;
      }
    }
    else if (this.dashboardConfig.Grid.SideNavPosition == SideNavPosition.BOTTOM) {
      if (this.sidenavCollapsed) {
        this.areasEl.forEach(area => {
          if (area.order == 2) {
            if (forceStateExpanded) {
              area.size = this.dashboardConfig.Grid.SideNavWidth;
            }
            area.expand();
          }
        });
        sidenavCollapsed = false;
      }
      else {
        this.areasEl.forEach(area => {
          if (area.order == 2) {
            area.collapse(30, 'left');
          }
        });
        sidenavCollapsed = true;
      }
    }

    setTimeout(() => {
      this.sidenavCollapsed = sidenavCollapsed;
      this.refresh();
    }, 100);
  }

  dropped(files: NgxFileDropEntry[]) {
    this.modalService.ShowConfirmModal({ header: 'Import dashboard', description: 'Import dashboard, are you sure?' }, (result) => {
      this.showImportDialog = false;
      if (result) {
        this.configfiles = files;
        for (const droppedFile of files) {

          if (droppedFile.fileEntry.isFile) {
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
              const reader = new FileReader();
              reader.onerror = (e) => {
                this.logger.info("Error importing file ");
                this.logger.info(e);
              }
              reader.onloadend = (e) => {
                const json = reader.result.toString().trim();
                try {
                  this.targetResolution = Resolutions.Sandbox;
                  let importedConfig = TypedJSON.parse(json, DashboardConfig);

                  this.dashboardConfig.update(importedConfig);
                  this.subscriptionsEnabled = this.enableSubscriptions && this.dashboardConfig.SubscriptionsEnabled;
                  this.originalDashboardConfig = TypedJSON.parse(TypedJSON.stringify(this.dashboardConfig, DashboardConfig), DashboardConfig);

                  if (this.dashboardConfig.Grid.ShowSideNav) {
                    setTimeout(() => {
                      this.toogleNav(true);
                    });
                  }

                  this.markedWidgetConfig = undefined;
                  this.copiedWidgetConfig = undefined;
                  this.sortWidgetConfigs();
                  this.setGridOptions();
                  this.gridoptions.api?.optionsChanged();
                  this.modified = true;
                }
                catch {
                  this.logger.info('Error import config!!!');
                }
              }
              reader.readAsText(file);

              //file.text() - not supported in all browser yet!
              // file.text().then(json => {
              //   try {
              //     let importedConfig = TypedJSON.parse(json, DashboardConfig);
              //     //this.logger.info('import', importedConfig)
              //     //this.config.Id = importedConfig.Id;
              //     this.config.LinkAllWidgets = importedConfig.LinkAllWidgets;
              //     this.config.WidgetConfigs = importedConfig.WidgetConfigs;
              //     this.config.Grid = importedConfig.Grid;
              //     this.dashboardService.saveDashboard(this.config);
              //   }
              //   catch {
              //     this.logger.info('Error import config!!!');
              //   }
              // });
            });
          } else {
            // It was a directory (empty directories are added, otherwise only files)
            const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
            this.logger.info(droppedFile.relativePath, fileEntry);
          }
        }
      }
    });
  }

}
