import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { XprojGroupSelectionComponent } from '../../../filters/group-selection/xproj-group-selection.component';
import { XprojProjectionFilterComponent } from '../../../filters/projection-filter/xproj-projection-filter.component';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { Aggregation, BaseQueryInputColumnDescription, ColumnGroupingDescription, Projection, ProjectionColumnDescription, Transformation, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { TypedJSON } from 'typedjson';
import { Cubical3dColumnConfig, Cubical3dWidgetQuery } from '../cubical3d-widget-config/xproj-cubical3d-widget-config-service';
import { GroupSelectionTypes, OutputDataType, WidgetConfig, WidgetInputParameter } from '../../widget-config-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';
import { environment } from 'src/environments/environment';

export class QueryProjectionData {
  projection: Projection;
  queryableSelectedGroup: string[] = [];
  columns: ProjectionColumnDescription[] = [];
  selectedGroup: string[] = [];
  usegrouping: boolean = false;
  groupingtransform: Transformation = Transformation.NONE;
  columnConfigs: Cubical3dColumnConfig[] = [];
  timefiltercolumn: string;
  defaultSortColumnName : string;
  defaultSortDescending : boolean;
  useTransformInputParameter : boolean = false;
  transformInputParameterId : string = '';
  useProjectionInputParameter : boolean;
  projectionInputParameterId : string = '';
  groupSelectionType : GroupSelectionTypes = GroupSelectionTypes.GROUP;
  groupInputParameterId: string = '';
  groupInputParameterIds: string[] = [];
}

@Component({
  selector: 'xproj-cubical3d-widget-query-config',
  templateUrl: './xproj-cubical3d-widget-query-config.component.html',
  styleUrls: ['./xproj-cubical3d-widget-query-config.component.scss']
})
export class XprojCubical3dWidgetQueryConfigComponent implements OnInit {

  @ViewChild("projectionFilter", { read: XprojProjectionFilterComponent, static: false }) projectionFilter: XprojProjectionFilterComponent;
  @ViewChild("groupSelect", { read: XprojGroupSelectionComponent, static: false }) groupSelect: XprojGroupSelectionComponent;

  // XprojSvgWidgetComponent
  @Input() widgetQuery: Cubical3dWidgetQuery;
  @Input() projections: Projection[] = [];
  @Input() inputParameters: WidgetInputParameter[] = [];
  @Input() widgets: WidgetConfig[];

  @Output() onQueryRemoved = new EventEmitter<Cubical3dWidgetQuery>();

  queryData: QueryProjectionData = new QueryProjectionData();

  selectedColumnConfig: any = null;
  Transformation = Transformation;
  Aggregation = Aggregation;
  OutputDataType = OutputDataType;
  GroupSelectionTypes = GroupSelectionTypes;

  editorOptionsLua = {
    theme: environment.editortheme,
    language: 'lua',
    automaticLayout: true,
    acceptSuggestionOnEnter: "smart",
    minimap: { enabled: true }
  };

  constructor(
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
    private xprojClient: XProjectorClient,
    private cdr: ChangeDetectorRef) {

  }

  async toggleBindText()
  {
    this.logger.info("should bind text");
    //let svg = document.getElementById(this.widgetQuery.Id);
    //this.logger.info(svg);
  }

  async ngOnInit() {
    this.queryData.selectedGroup = this.widgetQuery.Query.targetgroup
    this.queryData.columnConfigs = this.widgetQuery.ColumnConfigs;

    this.queryData.usegrouping = this.widgetQuery.UseGrouping;
    this.queryData.groupingtransform = this.widgetQuery.GroupingTransform;
    this.queryData.timefiltercolumn = this.widgetQuery.timestampColumnName;
    this.queryData.defaultSortColumnName = this.widgetQuery.defaultSortColumnName;
    this.queryData.defaultSortDescending = this.widgetQuery.defaultSortDescending;
    this.queryData.useTransformInputParameter = this.widgetQuery.UseTransformInputParameter;
    this.queryData.transformInputParameterId = this.widgetQuery.TransformInputParameterId;
    this.queryData.useProjectionInputParameter = this.widgetQuery.UseProjectionInputParameter;
    this.queryData.projectionInputParameterId = this.widgetQuery.ProjectionInputParameterId;
    this.queryData.groupSelectionType = this.widgetQuery.GroupSelectionType;
    this.queryData.groupInputParameterId = this.widgetQuery.GroupInputParameterId;
    this.queryData.groupInputParameterIds = this.widgetQuery.GroupInputParameterIds;
  }

  addAllColumns()
  {
    //this.queryData.columnConfigs.push(new ColumnConfig());

    for(let col of this.queryData.columns)
    {
      let t = new Cubical3dColumnConfig();
      t.ColumnName = col.columnname;
      t.ColumnOutName = col.columnname;
      t.Label = col.columnname;
      t.Datatype = col.datatype;
      t.Unit = col.unit;
      this.queryData.columnConfigs.push(t);
    }

  }
  removeAllColumns()
  {
    this.queryData.columnConfigs.length = 0;
  }

  addColumn() {
    //debugger;
    this.queryData.columnConfigs.push(new Cubical3dColumnConfig());
  }

  async ngAfterViewInit() {
    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }

    this.queryData.projection = this.projections.find(p => p.projectionid == this.widgetQuery.ProjectionId);

    //await this.selectedProjectionChange(this.queryData.projection);

    this.selectedProjectionGroupChange(this.queryData.selectedGroup);
  }

  async selectedProjectionChange(projection: Projection) {
    if (projection) {
      this.queryData.selectedGroup = null;
      this.queryData.queryableSelectedGroup.length = 0;
      await this.queryColumns(this.queryData.projection["projectionid"], null);
      this.checkTimeFilterColumn();
    }
  }

  async selectedProjectionGroupChange(group: any) {
    if (this.queryData.projection) {
      this.queryData.selectedGroup = group;
      this.queryData.queryableSelectedGroup.length = 0;
      for (let gr of group) {
        this.queryData.queryableSelectedGroup.push(gr);
      }
      //this.logger.info('selectedProjectionGroupChange', group);
      await this.queryColumns(this.queryData.projection["projectionid"], group);
      this.checkTimeFilterColumn();
    }
  }

  async queryColumns(projectionId: string, group: Array<string>) {
    //this.loadingProjectionColumns = true;
    let groupstr = "";
    if (group) {
      groupstr = group.join(",");
    }
    this.queryData.columns = await this.xprojClient.RequestListQueryableProjectionColumns(projectionId, groupstr, 0, 500);
    //this.loadingProjectionColumns = false;

  }

  checkTimeFilterColumn(force: boolean = false): void {
    if (!this.queryData.columns.find(col => col.columnname == this.queryData.timefiltercolumn)
      || (!this.widgetQuery.timestampColumnName || this.widgetQuery.timestampColumnName.length == 0)) {
      this.queryData.timefiltercolumn = '';
    }

    let found = false;
    //Look for timestamp or time column
    if (force || !this.queryData.timefiltercolumn || this.queryData.timefiltercolumn.length == 0) {
      this.queryData.columns.forEach(col => {
        if (col.datatype == XDataType.Timestamp &&
          (col.columnname.toLowerCase() == 'timestamp' || col.columnname.toLowerCase() == 'time')) {
          this.queryData.timefiltercolumn = col.columnname;
          found = true;
        }
      });
    }
    //Take first column with Timestamp type if not found before.
    if (!found && (force || !this.queryData.timefiltercolumn || this.queryData.timefiltercolumn.length == 0)) {
      this.queryData.columns.forEach(col => {
        if (col.datatype == XDataType.Timestamp) {
          this.queryData.timefiltercolumn = col.columnname;
        }
      });
    }
  }

  removeQuery() {
    this.onQueryRemoved?.emit(this.widgetQuery);
  }

  removeColumn(index: number) {
    ArrayUtils.RemoveItemAt(this.queryData.columnConfigs, index);
  }

  moveColumnUp(index: number) {
    ArrayUtils.MoveItemUp(this.queryData.columnConfigs, index);
    this.refreshColumnConfigs();
  }
  moveColumnDown(index: number) {
    ArrayUtils.MoveItemDown(this.queryData.columnConfigs, index);
    this.refreshColumnConfigs();
  }

  private refreshColumnConfigs() {
    let copy = [...this.queryData.columnConfigs];
    this.queryData.columnConfigs = [];
    this.cdr.detectChanges();
    this.queryData.columnConfigs = copy;
  }

  removeSelectedColumn() {
    let counter = 0;
    for (let i of this.queryData.columnConfigs) {
      if (i == this.selectedColumnConfig)
        break;
      counter++;
    }
    this.removeColumn(counter);
    this.selectedColumnConfig = null;
  }

  onColumnSelect($event, index) {
    let column = this.queryData.columns.find(col => col.columnname == $event);
    if (column && column.unit?.length > 0 && index < this.queryData.columnConfigs.length) {
      this.queryData.columnConfigs[index].Unit = column.unit;
    }
    if (column) {
      this.queryData.columnConfigs[index].Datatype = column.datatype;
    }
  }

  async SaveQuery() {
    //convert from literal object to class object
    this.widgetQuery.DataFilters.forEach(filter => {
      filter.ColumnDescriptor = TypedJSON.parse(JSON.stringify(filter.ColumnDescriptor), ProjectionColumnDescription);
    });

    //this.widgetQuery.Query = this.projectionFilter.GetQuery();
    this.widgetQuery.DataFilters = this.projectionFilter.datafilters;
    this.widgetQuery.FilterLogicalGroupType = this.projectionFilter.filterLogicalGroupType;
    this.widgetQuery.Query.maxitems = 2000;

    this.widgetQuery.ProjectionId = this.queryData.projection.projectionid;
    this.widgetQuery.Group = this.queryData.selectedGroup;
    this.widgetQuery.Query.targetprojectionid = this.widgetQuery.ProjectionId;

    if (this.queryData.columnConfigs.length == 0) {
      return;
    }

    // if(this.widgetQuery.UseColumnMatching)
    // {
    //   let matchcol = new BaseQueryInputColumnDescription();
    //   matchcol.columnname = this.widgetQuery.MatchColumnName;
    //   matchcol.columnoutname = "match";
    //   this.widgetQuery.Query.columns.push(matchcol);

    //   let valuecol = new BaseQueryInputColumnDescription();
    //   valuecol.columnname = this.widgetQuery.ValueColumnName;
    //   valuecol.columnoutname = "value";
    //   this.widgetQuery.Query.columns.push(valuecol);

    //   return;
    // }

    // let addedColumns = {};

    // let colindex = 0;
    // for (let column of this.queryData.columnConfigs) {
    //   if(addedColumns[column.ColumnName] )
    //   {
    //     continue;
    //   }
    //   addedColumns[column.ColumnName] = true;
    //   let col = new BaseQueryInputColumnDescription();
    //   col.columnname = column.ColumnName;
    //   if (column.Label?.length > 0) {
    //     col.columnoutname = column.ColumnOutName = column.Label;
    //   }
    //   else {
    //     col.columnoutname = column.ColumnOutName = this.queryData.usegrouping ? "col_" + colindex.toString() : column.ColumnName;
    //   }

    //   if (colindex == 0) {
    //     if (!this.queryData.usegrouping) {
    //       this.widgetQuery.Query.sorting.columnname = col.columnname;
    //       this.widgetQuery.Query.sorting.descending = false;
    //       this.widgetQuery.Query.grouping = new ColumnGroupingDescription();
    //       this.widgetQuery.Query.columns.push(col);
    //     }
    //     else {
    //       this.widgetQuery.Query.grouping.columnname = col.columnname;
    //       this.widgetQuery.Query.grouping.columntransformation = this.queryData.groupingtransform;
    //       this.widgetQuery.Query.grouping.columnoutname = col.columnoutname;
    //     }
    //   }
    //   else {
    //     if (this.queryData.usegrouping) {
    //       col.columnaggregation = column.Transform;
    //     }
    //     this.widgetQuery.Query.columns.push(col);
    //   }

    //   colindex++;
    // }

    this.widgetQuery.ColumnConfigs = this.queryData.columnConfigs;

    this.widgetQuery.UseGrouping = this.queryData.usegrouping;
    this.widgetQuery.GroupingTransform = this.queryData.groupingtransform;
    this.widgetQuery.timestampColumnName = this.queryData.timefiltercolumn;
    this.widgetQuery.defaultSortColumnName = this.queryData.defaultSortColumnName;
    this.widgetQuery.defaultSortDescending = this.queryData.defaultSortDescending;
    this.widgetQuery.UseTransformInputParameter = this.queryData.useTransformInputParameter;
    this.widgetQuery.TransformInputParameterId = this.queryData.transformInputParameterId;
    this.widgetQuery.UseProjectionInputParameter = this.queryData.useProjectionInputParameter;
    this.widgetQuery.ProjectionInputParameterId = this.queryData.projectionInputParameterId;
    this.widgetQuery.GroupSelectionType = this.queryData.groupSelectionType;
    this.widgetQuery.GroupInputParameterId = this.queryData.groupInputParameterId;
    this.widgetQuery.GroupInputParameterIds = this.queryData.groupInputParameterIds;

    if (this.groupSelect) {
      this.widgetQuery.Query.targetgroup = this.groupSelect.getSelectedGroup();
    }
  }

}
