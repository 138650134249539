import { Component, Input, OnInit, ViewChild, inject } from '@angular/core';
import { ClrDatagridSortOrder, ClrLoadingState } from '@clr/angular';
import { Customer } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XprojBimBuildingService } from '../../services/xproj-bim-building.service';
import { BimBuildingaddress } from '../../models/bim-buildingaddress';
import { FloorplanEditorComponent } from '../bms-floorplan-editor/bms-floorplan-editor.component';

@Component({
  selector: 'app-bms-building-editor',
  templateUrl: './bms-building-editor.component.html',
  styleUrls: ['./bms-building-editor.component.scss']
})
export class BmsBuildingEditorComponent implements OnInit {

  @ViewChild("florplansEditor", { read: FloorplanEditorComponent, static: false }) florplansEditor: FloorplanEditorComponent;

  @Input() nodeId : string;
  @Input() nodeTypeId : string;
  @Input() customer : Customer;
  @Input() buildingService : XprojBimBuildingService;

  sizeOptions = [10, 20, 50, 100];
  ascSort = ClrDatagridSortOrder.ASC;

  buildingAddresses : BimBuildingaddress[] = [];

  loadingBuildingAddresses : boolean = false;
  showSaved : boolean = false;

  constructor() { }

  ngOnInit() {
    this.updateBuildingAddresses();
  }

  async updateBuildingAddresses() {
    if (this.buildingService) {
      this.buildingAddresses = await this.buildingService.getBimBuildingaddresses(+this.nodeId);
      this.buildingAddresses.sort((a,b) => a.description > b.description ? 1 : 0);
    }
  }

  async save() {
    let result = await this.buildingService.setBimBuildingaddresses(+this.nodeId, this.buildingAddresses, this.customer.id);
    result &&= await this.florplansEditor?.saveFloorplan();
    if (result) {
      this.showSaved = true;
      setTimeout(() => {
        this.showSaved = false;
      }, 2000);
    }
  }
}
