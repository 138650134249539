<main class="content-area">

  <!-- {{rossakerState.customers|json}} -->
  <!-- For mobile this is a fallback for broken clarity component combobox.. -->
  <!-- <div class="selectcustomer" id="selectcustomer">

      <label>Select account:</label>
      <clr-combobox [(ngModel)]="selectedCustomer" name="customerselect" (ngModelChange)="currentCustomerChanged($event)"
        (clrOpenChange)="customerSelectOpenChange($event)">
        <clr-options>
          <clr-option *clrOptionItems="let customer of rossakerState.customers; field:'name'" [clrValue]="customer">
            {{customer.name}}
          </clr-option>
        </clr-options>
      </clr-combobox>


  </div> -->

  <router-outlet></router-outlet>
</main>




<clr-vertical-nav [clr-nav-level]="2" [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="collapsed"
  class="nav-trigger--bottom">
  <div class="customer-admin-top">
  <!-- <label>Select account:</label> -->
  <clr-combobox [(ngModel)]="selectedCustomer" name="customerselect" (ngModelChange)="currentCustomerChanged($event)"
    (clrOpenChange)="customerSelectOpenChange($event)">
    <clr-options>
      <clr-option *clrOptionItems="let customer of rossakerState.customers; field:'name'" [clrValue]="customer">
        {{customer.name}}
      </clr-option>
    </clr-options>
  </clr-combobox>

  <clr-vertical-nav-group-children>
    <a clrVerticalNavLink [routerLink]="['customeroverview']" routerLinkActive="active">
      <clr-icon clrVerticalNavIcon shape="home"></clr-icon>
      Overview
    </a>
  </clr-vertical-nav-group-children>
  <clr-vertical-nav-group-children>
    <a clrVerticalNavLink [routerLink]="['customeriiot']" routerLinkActive="active">
      <clr-icon clrVerticalNavIcon shape="factory"></clr-icon>
      Plant view
    </a>
  </clr-vertical-nav-group-children>

  <clr-vertical-nav-group-children>
    <a clrVerticalNavLink [routerLink]="['customerbms']" routerLinkActive="active">
      <clr-icon clrVerticalNavIcon shape="building"></clr-icon>
      BMS view
    </a>
  </clr-vertical-nav-group-children>
  <clr-vertical-nav-group-children>
    <a clrVerticalNavLink [routerLink]="['customerrealestates']" routerLinkActive="active">
      <clr-icon clrVerticalNavIcon shape="bank"></clr-icon>
      Realestates
    </a>
  </clr-vertical-nav-group-children>

  <clr-vertical-nav-group-children>
    <a clrVerticalNavLink [routerLink]="['datacollectors']" routerLinkActive="active">
      <clr-icon clrVerticalNavIcon shape="cloud-traffic"></clr-icon>
      Metering gateways
    </a>
  </clr-vertical-nav-group-children>
  <clr-vertical-nav-group-children>
    <a clrVerticalNavLink [routerLink]="['meteradmin']" routerLinkActive="active">
      <clr-icon clrVerticalNavIcon shape="scatter-plot"></clr-icon>
      Meters
    </a>
  </clr-vertical-nav-group-children>

  <clr-vertical-nav-group-children>
    <a clrVerticalNavLink [routerLink]="['insightdashboards']" routerLinkActive="active">
      <clr-icon clrVerticalNavIcon shape="dashboard"></clr-icon>
      Insights
    </a>
  </clr-vertical-nav-group-children>

  <clr-vertical-nav-group-children>
    <a clrVerticalNavLink [routerLink]="['events']" routerLinkActive="active">
      <clr-icon clrVerticalNavIcon shape="event"></clr-icon>
      Events
    </a>
  </clr-vertical-nav-group-children>
  <!-- <clr-vertical-nav-group routerLinkActive="false">
    <clr-icon clrVerticalNavIcon shape="dashboard"></clr-icon>
        Insights
    <clr-vertical-nav-group-children>
      <app-rossaker-bms-admin-dashboards></app-rossaker-bms-admin-dashboards>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group> -->



  <clr-vertical-nav-group-children>
    <a clrVerticalNavLink [routerLink]="['users/true']" routerLinkActive="active">
      <clr-icon clrVerticalNavIcon shape="users"></clr-icon>
      Users
    </a>
  </clr-vertical-nav-group-children>
</div>
  <!-- <hr style="width:100%; padding:0;margin:0; border: 2px dotted rgba(0,0,0,0.8);"> -->
  <div class="nav-divider"></div>
  <div class="customer-admin-bottom">
  <clr-vertical-nav-group-children>
    <a clrVerticalNavLink [routerLink]="['importcustomer']" routerLinkActive="active">
      <clr-icon clrVerticalNavIcon shape="import"></clr-icon>
      Import account
    </a>
  </clr-vertical-nav-group-children>

  <clr-vertical-nav-group-children>
        <a clrVerticalNavLink [routerLink]="['users']" routerLinkActive="active">
          <clr-icon clrVerticalNavIcon shape="users"></clr-icon>
  Admin users
</a>
    </clr-vertical-nav-group-children>

  </div>
  <!-- <clr-vertical-nav-group-children>
        <a clrVerticalNavLink [routerLink]="['users']" routerLinkActive="active">
  Admin users
</a>
    </clr-vertical-nav-group-children> -->



  <!-- <clr-vertical-nav-group routerLinkActive="false">
        <clr-icon clrVerticalNavIcon shape="helix"></clr-icon>
        Administration

        <clr-vertical-nav-group-children>
            <a clrVerticalNavLink [routerLink]="['customeroverview']" routerLinkActive="active">
        Overview
      </a>
            <a clrVerticalNavLink [routerLink]="['customerbms']" routerLinkActive="active">
        BMS view
      </a>
            <a clrVerticalNavLink [routerLink]="['datacollectors']" routerLinkActive="active">
        Data collectors view
      </a>
            <a clrVerticalNavLink [routerLink]="['customerrealestates']" routerLinkActive="active">
        Realestates view
      </a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group> -->
  <!--
  <clr-vertical-nav-group routerLinkActive="false">
    <clr-icon clrVerticalNavIcon shape="helix"></clr-icon>
    Dashboards
    <clr-vertical-nav-group-children>
      <app-rossaker-bms-admin-dashboards></app-rossaker-bms-admin-dashboards>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group> -->

  <!-- <clr-vertical-nav-group routerLinkActive="false">
        <clr-icon clrVerticalNavIcon shape="helix"></clr-icon>
        Meters

        <clr-vertical-nav-group-children>
            <a clrVerticalNavLink [routerLink]="['meteradmin']" routerLinkActive="active">
        Meters view
      </a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group> -->
  <!--
    <clr-vertical-nav-group routerLinkActive="false">
        <clr-icon clrVerticalNavIcon shape="helix"></clr-icon>
        Configuration

        <clr-vertical-nav-group-children>
            <a clrVerticalNavLink [routerLink]="['importcustomer']" routerLinkActive="active">
        Import customer
      </a>
            -- <a clrVerticalNavLink [routerLink]="['geolocation']" routerLinkActive="active" --
        Geo location
      </a>
    </clr-vertical-nav-group-children>
    </clr-vertical-nav-group> -->

  <!-- <clr-vertical-nav-group routerLinkActive="false">
        <clr-icon clrVerticalNavIcon shape="helix"></clr-icon>
        Users

        <clr-vertical-nav-group-children>
            <a clrVerticalNavLink [routerLink]="['users/true']" routerLinkActive="active">
        Customer users
      </a>
            <a clrVerticalNavLink [routerLink]="['users']" routerLinkActive="active">
        Admin users
      </a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group> -->


</clr-vertical-nav>
