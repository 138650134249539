import { Type } from '@angular/core';

export class XEdgeToken {

  public token : string;
  public remoteNodeId : string;
  public timestamp : string;
  public host : string;
  public name : string;
}
