import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { Cubical3dWidgetConfig, Cubical3dWidgetQuery } from './xproj-cubical3d-widget-config-service';
import { OutputDataType, WidgetConfig, WidgetInputParameter, WidgetOutputParameter } from '../../widget-config-service';
import { Subscription } from 'rxjs';
import { XprojWidgetService } from '../../xproj-widget-service';
import { Aggregation, Projection, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { XprojCubical3dWidgetQueryConfigComponent } from '../cubical3d-widget-query-config/xproj-cubical3d-widget-query-config.component';
import { WidgetConfigBase } from '../../widget-config-base';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'xproj-cubical3d-widget-config',
  templateUrl: './xproj-cubical3d-widget-config.component.html',
  styleUrls: ['./xproj-cubical3d-widget-config.component.scss']
})
export class XprojCubical3dWidgetConfigComponent extends WidgetConfigBase implements OnInit, OnDestroy {

  widgetConfig: Cubical3dWidgetConfig;

  editorOptionsLua = {
    theme: environment.editortheme,
    language: 'lua',
    automaticLayout: true,
    acceptSuggestionOnEnter: "smart",
    minimap: { enabled: true }
  };


  @ViewChildren(XprojCubical3dWidgetQueryConfigComponent, { read: XprojCubical3dWidgetQueryConfigComponent }) widgetQueryConfigs: QueryList<XprojCubical3dWidgetQueryConfigComponent>;

  projections: Projection[] = [];

  constructor(public xprojClient: XProjectorClient, public widgetService: XprojWidgetService) {
    super(xprojClient, widgetService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }


  async ngAfterViewInit() {
    //console.log('widgetQueryConfigs', this.widgetQueryConfigs);
  }

  async ngOnInit() {
    this.widgetConfig = this.config as Cubical3dWidgetConfig;

    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }
    // if (this.widgetConfig.ConfigQueries.length == 0) {
    //   this.addConfigQuery();
    // }
  }

  addConfigQuery(): void {
    this.widgetConfig.ConfigQueries.push(new Cubical3dWidgetQuery());
  }

  onQueryRemoved(query: Cubical3dWidgetQuery) {
    this.widgetConfig.ConfigQueries = this.widgetConfig.ConfigQueries.filter(q => q != query);
  }

  async onSaveConfig() {

    //console.log('SaveConfig', this.widgetQueryConfigs.toArray());
    this.widgetQueryConfigs.toArray().forEach(queryConfig => {
      queryConfig.SaveQuery();
    });

    this.widgetConfig.OutputParameters = [];

    let output = new WidgetOutputParameter();
    output.id = this.widgetConfig.Id + '_matchcolumnvalue';
    output.name = 'MatchColumnValue';
    output.datatype = OutputDataType.String;
    this.widgetConfig.OutputParameters.push(output);

    output = new WidgetOutputParameter();
    output.id = this.widgetConfig.Id + '_position';
    output.name = 'Position';
    output.datatype = OutputDataType.String;
    this.widgetConfig.OutputParameters.push(output);

    output = new WidgetOutputParameter();
    output.id = this.widgetConfig.Id + '_columnname';
    output.name = 'ColumnName';
    output.datatype = OutputDataType.String;
    this.widgetConfig.OutputParameters.push(output);

    output = new WidgetOutputParameter();
    output.id = this.widgetConfig.Id + '_columnoutname';
    output.name = 'ColumnOutName';
    output.datatype = OutputDataType.String;
    this.widgetConfig.OutputParameters.push(output);

    output = new WidgetOutputParameter();
    output.id = this.widgetConfig.Id + '_unit';
    output.name = 'Unit';
    output.datatype = OutputDataType.String;
    this.widgetConfig.OutputParameters.push(output);

    output = new WidgetOutputParameter();
    output.id = this.widgetConfig.Id + '_dblclick';
    output.name = 'DblClick';
    output.datatype = OutputDataType.UInt8;
    this.widgetConfig.OutputParameters.push(output);
  }

}
