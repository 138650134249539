import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClarityModule } from '@clr/angular';

import { RossakerBmsAdminRoutingModule } from './rossaker-bms-admin-routing.module';
import { RossakerBmsAdminWorkspaceComponent } from './components/rossaker-bms-admin-workspace/rossaker-bms-admin-workspace.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { XconfModule } from '@xprojectorfeatures/xconf/xconf.module';
import { RossakerBmsCustomersAdminComponent } from './components/rossaker-bms-customers-admin/rossaker-bms-customers-admin.component';
import { SysAdminModule } from '@xprojectorfeatures/sysadmin/sys-admin.module';
import { RossakerBmsDataCollectorsComponent } from './components/rossaker-bms-data-collectors/rossaker-bms-data-collectors.component';
import { RossakerBmsDataCollectorViewComponent } from './components/rossaker-bms-data-collector-view/rossaker-bms-data-collector-view.component';
import { RossakerBmsLorawanMetersGroupDataCollectorViewComponent } from './components/rossaker-bms-data-collector-views/rossaker-bms-lorawan-meters-group-data-collector-view/rossaker-bms-lorawan-meters-group-data-collector-view.component';
import { RossakerStateService } from '@core/services/rossaker-state-service';
import { RossakerBmsDataCollectorView } from '@core/models/rossaker-bms-data-collector-view';
import { RossakerBmsDataCollectorViewDirective } from './directives/rossaker-bms-data-collector-view.directive';
import { RossakerBmsLorawanMeterDataCollectorViewComponent } from './components/rossaker-bms-data-collector-views/rossaker-bms-lorawan-meter-data-collector-view/rossaker-bms-lorawan-meter-data-collector-view.component';
import { RossakerBmsLorawanMultiMeterDataCollectorViewComponent } from './components/rossaker-bms-data-collector-views/rossaker-bms-lorawan-multi-meter-data-collector-view/rossaker-bms-lorawan-multi-meter-data-collector-view.component';
import { RossakerBmsOverviewComponent } from './components/rossaker-bms-overview/rossaker-bms-overview.component';
import { RossakerBmsRealestateViewComponent } from './components/rossaker-bms-realestate-view/rossaker-bms-realestate-view.component';
import { RossakerBmsCustomerImportComponent } from './components/rossaker-bms-customer-import/rossaker-bms-customer-import.component';
import { RossakerBmsAdminDashboardsComponent } from './components/rossaker-bms-admin-dashboards/rossaker-bms-admin-dashboards.component';
import { RossakerBmsAdminDashboardComponent } from './components/rossaker-bms-admin-dashboard/rossaker-bms-admin-dashboard.component';
import { RossakerBmsMbusMeterDataCollectorViewComponent } from './components/rossaker-bms-data-collector-views/rossaker-bms-mbus-meter-data-collector-view/rossaker-bms-mbus-meter-data-collector-view.component';
import { RossakerBmsXEdgeServerDataCollectorViewComponent } from './components/rossaker-bms-data-collector-views/rossaker-bms-xedge-server-data-collector-view/rossaker-bms-xedge-server-data-collector-view.component';
import { RossakerBmsDefaultDataCollectorViewComponent } from './components/rossaker-bms-data-collector-views/rossaker-bms-default-data-collector-view/rossaker-bms-default-data-collector-view.component';
import { RossakerBmsBuildingDataCollectorViewComponent } from './components/rossaker-bms-data-collector-views/rossaker-bms-building-data-collector-view/rossaker-bms-building-data-collector-view.component';
import { RossakerBmsEventsComponent } from './components/rossaker-bms-admin-events/rossaker-bms-events/rossaker-bms-events.component';
import { RossakerBmsEventsHistoryComponent } from './components/rossaker-bms-admin-events/rossaker-bms-events-history/rossaker-bms-events-history.component';
import { RossakerBmsEventStatusPipe } from './pipes/rossaker-bms-event-status.pipe';
import { RossakerBmsEventActivePipe } from './pipes/rossaker-bms-event-active.pipe';

import { XEdgeModule } from '@xprojectorfeatures/xedge/xedge.module';
import { BimModule } from '@xprojectorfeatures/bim/bim.module';
import { RossakerIiotCustomersAdminComponent } from './components/rossaker-iiot-customers-admin/rossaker-iiot-customers-admin.component';

@NgModule({
  declarations: [
    RossakerBmsAdminWorkspaceComponent,
    RossakerBmsCustomersAdminComponent,
    RossakerBmsDataCollectorsComponent,
    RossakerBmsDataCollectorViewComponent,
    RossakerBmsLorawanMetersGroupDataCollectorViewComponent,
    RossakerBmsDataCollectorViewDirective,
    RossakerBmsLorawanMeterDataCollectorViewComponent,
    RossakerBmsLorawanMultiMeterDataCollectorViewComponent,
    RossakerBmsOverviewComponent,
    RossakerBmsRealestateViewComponent,
    RossakerBmsCustomerImportComponent,
    RossakerBmsAdminDashboardsComponent,
    RossakerBmsAdminDashboardComponent,
    RossakerBmsMbusMeterDataCollectorViewComponent,
    RossakerBmsDefaultDataCollectorViewComponent,
    RossakerBmsXEdgeServerDataCollectorViewComponent,
    RossakerBmsBuildingDataCollectorViewComponent,
    RossakerIiotCustomersAdminComponent,
    RossakerBmsEventsComponent,
    RossakerBmsEventsHistoryComponent,
    RossakerBmsEventStatusPipe,
    RossakerBmsEventActivePipe,


  ],
  imports: [
    //ClarityModule,
    CommonModule,
    RossakerBmsAdminRoutingModule,
    FormsModule,
    SharedModule,
    XconfModule,
    SysAdminModule,
    XEdgeModule,
    BimModule
  ]
})
export class RossakerBmsAdminModule {
  constructor(private rossakerState: RossakerStateService, @Optional() @SkipSelf() parentModule?: RossakerBmsAdminModule) {
    rossakerState.addDataCollectorView(new RossakerBmsDataCollectorView(
      RossakerBmsDefaultDataCollectorViewComponent,
      RossakerBmsDefaultDataCollectorViewComponent.NodeTypeId
    ));
    rossakerState.addDataCollectorView(new RossakerBmsDataCollectorView(
      RossakerBmsLorawanMetersGroupDataCollectorViewComponent,
      RossakerBmsLorawanMetersGroupDataCollectorViewComponent.NodeTypeId
    ));
    rossakerState.addDataCollectorView(new RossakerBmsDataCollectorView(
      RossakerBmsLorawanMultiMeterDataCollectorViewComponent,
      RossakerBmsLorawanMultiMeterDataCollectorViewComponent.NodeTypeId
    ));
    rossakerState.addDataCollectorView(new RossakerBmsDataCollectorView(
      RossakerBmsLorawanMeterDataCollectorViewComponent,
      RossakerBmsLorawanMeterDataCollectorViewComponent.NodeTypeId
    ));
    rossakerState.addDataCollectorView(new RossakerBmsDataCollectorView(
      RossakerBmsMbusMeterDataCollectorViewComponent,
      RossakerBmsMbusMeterDataCollectorViewComponent.NodeTypeId
    ));
    rossakerState.addDataCollectorView(new RossakerBmsDataCollectorView(
      RossakerBmsXEdgeServerDataCollectorViewComponent,
      RossakerBmsXEdgeServerDataCollectorViewComponent.NodeTypeId
    ));
    rossakerState.addDataCollectorView(new RossakerBmsDataCollectorView(
      RossakerBmsBuildingDataCollectorViewComponent,
      RossakerBmsBuildingDataCollectorViewComponent.NodeTypeId
    ));

  }
}
