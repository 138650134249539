import { Component, OnInit } from '@angular/core';
import { ClrDatagridSortOrder, ClrLoadingState } from '@clr/angular';
import { XAUTO_DriverType, XAUTO_ModbusRTUMode, XAUTO_ModbusRTUParity, XAUTO_ModbusType, XAUTO_ModbusVariableByteOrder, XAUTO_ModbusVariableRegisterOrder, XAUTO_ModbusVariableType, XAUTO_XAutoDriver, XAUTO_XAutoDriverModbus, XAUTO_XAutoVariable, XAUTO_XAutoVariableModbus, XDataType, XProjectorClient } from '../../XProjector/xprojector-client-service';
import * as uuid from 'uuid';
import * as XLSX from 'xlsx';

export class ViewVariable{
  public Variable : XAUTO_XAutoVariable;
  public Modbus : XAUTO_XAutoVariableModbus;
}

export class ViewDriver{
  public Driver : XAUTO_XAutoDriver;
  public Modbus : XAUTO_XAutoDriverModbus;
  public Variables: Array<ViewVariable> = new Array<ViewVariable>();
}


@Component({
  selector: 'xproj-editconnectors-modbus',
  templateUrl: './editconnectors-modbus.component.html',
  styleUrls: ['./editconnectors-modbus.component.scss']
})
export class EditconnectorsModbusComponent implements OnInit {

  sizeOptions = [10, 20, 50, 100];
  ascSort = ClrDatagridSortOrder.ASC;  
  public loadingConnectors = false;  
  savingDriver : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingRemovingDriver : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingRemovingVariable : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingVariable : ClrLoadingState =ClrLoadingState.DEFAULT;
  
  XAUTO_ModbusType = XAUTO_ModbusType;
  XAUTO_ModbusVariableType = XAUTO_ModbusVariableType;
  XAUTO_ModbusVariableByteOrder = XAUTO_ModbusVariableByteOrder;
  XAUTO_ModbusVariableRegisterOrder = XAUTO_ModbusVariableRegisterOrder;

  XAUTO_ModbusRTUMode = XAUTO_ModbusRTUMode;
  XAUTO_ModbusRTUParity = XAUTO_ModbusRTUParity;


  XDataType = XDataType;
  public showAddConnector : boolean = false;

  public connectors = [];
  public selectedConnector : ViewDriver = null;
  public selectedVariable : ViewVariable = null;
  

  constructor(private xprojClient: XProjectorClient) {
  }


  /*
  importFromFileExcel(filename: string): XLSX.AOA2SheetOpts {

    const wb: XLSX.WorkBook = XLSX.read(filename, { type: 'binary' });

    // grab first sheet 
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

    return <XLSX.AOA2SheetOpts>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
  }

  async importDataExcel(data: any[][])
  {
    if(data.length<1)
      return;

    if (this.tableData.columns.length >= data[0].length)
    {

    }


  }*/

  exportViewVariable(ev :ViewVariable) : object
  {
    let item = {};
    for(let t in ev.Variable)
    {
      if(t == "xautodriverid")
        continue;
      if(t == "xautovariableid")
        continue;
      if(t.endsWith("utc"))
        continue;

      if(t == "transform")
      {
        for(let ts in ev.Variable[t])
        {
          item["transform_" + ts] = ev.Variable[t][ts].toString();
        }
        continue;        
      }

      item[t] = ev.Variable[t].toString();
    }
    for(let t in ev.Modbus)
    {
      if(t == "xautodriverid")
        continue;
      if(t == "xautovariableid")
        continue;
      if(t.endsWith("utc"))
        continue;
      item["modbus_" + t] = ev.Modbus[t].toString();
    }
    return item;
  }

//   get_header_row(sheet) : any {
//     var headers = [];
//     var range = XLSX.utils.decode_range(sheet['!ref']);
//     var C, R = range.s.r; /* start in the first row */
//     /* walk every column in the range */
//     for(C = range.s.c; C <= range.e.c; ++C) {
//         var cell = sheet[XLSX.utils.encode_cell({c:C, r:R})] /* find the cell in the first row */

//         var hdr = "UNKNOWN " + C; // <-- replace with your desired default 
//         if(cell && cell.t) hdr = XLSX.utils.format_cell(cell);

//         headers.push(hdr);
//     }
//     return headers;
// }

  async export()
  {
    let exportvariables = [];

    for(let ev of this.selectedConnector.Variables )
    {  
      let item = this.exportViewVariable(ev);
      exportvariables.push(item);
    }

    const ws  = XLSX.utils.json_to_sheet( exportvariables);
    
    ws['!cols'] = [];
    //ws['!cols'][1] = { hidden: true };
    
    let range = XLSX.utils.decode_range(ws['!ref']);
    for(let C= range.s.c; C <= range.e.c; ++C) {
      let cell = ws[XLSX.utils.encode_cell({ c : C, r : range.s.r })] /* find the cell in the first row */

      if(cell["v"] == "transform_luain")
      {
        ws['!cols'][C] = {hidden : true};
      }
      //var hdr = "UNKNOWN " + C; // <-- replace with your desired default 
      //if(cell && cell.t) hdr = XLSX.utils.format_cell(cell);

      // headers.push(hdr);
  }

    // for(let t in ws)
    // {
    //   if(ws[t]["v"] == "transform_luain")
    //   {
    //     ws[t]["hidden"] = true;
    //   }
    // }

    // ws['!cols']

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Variables");
    XLSX.writeFile( wb, this.selectedConnector.Driver.name + "-variables.xlsx" );
  }

  async saveAll()
  {
    for(let i = 0; i < this.selectedConnector.Variables.length; i++)
    {
      await this.xprojClient.XAUTO_SaveVariable(this.selectedConnector.Variables[i].Variable);
      await this.xprojClient.XAUTO_SaveVariableModbus(this.selectedConnector.Variables[i].Modbus);
    }
  }

  async enableAll()
  {    
    for(let i = 0; i < this.selectedConnector.Variables.length; i++)
    {
      this.selectedConnector.Variables[i].Variable.enabled = true;
    }
    await this.saveAll();
  }
  
  async disableAll()
  {
    for(let i = 0; i < this.selectedConnector.Variables.length; i++)
    {
      this.selectedConnector.Variables[i].Variable.enabled = false;
    }
    await this.saveAll();
  }

  removeVariable()
  {
    this.savingRemovingVariable = ClrLoadingState.LOADING;
    this.savingRemovingVariable = ClrLoadingState.SUCCESS;
  }

  addXautoVariable()
  {    
    this.selectedVariable.Variable = new XAUTO_XAutoVariable();
    this.selectedVariable.Variable.xautogroup = this.selectedConnector.Driver.defaultxautogroup;
    this.selectedVariable.Variable.xgroup = this.selectedConnector.Driver.defaultxgroup;
    this.selectedVariable.Variable.xautodriverid= this.selectedConnector.Driver.xautodriverid;
    this.selectedVariable.Variable.xautovariableid = uuid.v4();
    this.selectedVariable.Variable.type = XDataType.Float64;
    //this.selectedConnector.Variables
  }

  addVariable()
  {  
    this.selectedVariable = new ViewVariable();
    this.addXautoVariable();
    this.selectedVariable.Variable.name = "New variable";
    this.selectedVariable.Modbus = new XAUTO_XAutoVariableModbus();
    this.selectedVariable.Modbus.xautodriverid = this.selectedVariable.Variable.xautodriverid;
    this.selectedVariable.Modbus.xautovariableid = this.selectedVariable.Variable.xautovariableid;

    this.selectedConnector.Variables.push(this.selectedVariable);
  }

  async saveVariable()
  {
    this.savingVariable = ClrLoadingState.LOADING;

    try
    {
      this.savingVariable = ClrLoadingState.SUCCESS;
      console.log("save variable");
      await this.xprojClient.XAUTO_SaveVariable( this.selectedVariable.Variable );
      console.log("save variable modbus");
      await this.xprojClient.XAUTO_SaveVariableModbus( this.selectedVariable.Modbus );
      console.log("save variable modbus done");
    }
    catch
    {
      this.savingVariable = ClrLoadingState.ERROR;
    }
  }

  removeDriver()
  {
    this.savingRemovingDriver = ClrLoadingState.LOADING;
    this.savingRemovingDriver = ClrLoadingState.SUCCESS;

  }

  async saveDriver()
  {
    this.savingDriver = ClrLoadingState.LOADING;    

    try
    {
      await this.xprojClient.XAUTO_SaveDriver(this.selectedConnector.Driver);
      await this.xprojClient.XAUTO_SaveDriverModbus(this.selectedConnector.Modbus);
      this.savingDriver = ClrLoadingState.SUCCESS;
    }
    catch
    {
      this.savingDriver = ClrLoadingState.ERROR;
    }
  }

  addXAutoDriver()
  {
    this.selectedConnector = new ViewDriver();

    this.selectedConnector.Driver = new XAUTO_XAutoDriver();
    this.selectedConnector.Driver.xautodriverid = uuid.v4();        
  }
  addConnector()
  {
    this.addXAutoDriver();
    this.selectedConnector.Driver.name = "New Modbus";
    this.selectedConnector.Driver.driver = XAUTO_DriverType.MODBUS;
    this.selectedConnector.Modbus = new XAUTO_XAutoDriverModbus();    
    this.selectedConnector.Modbus.xautodriverid = this.selectedConnector.Driver.xautodriverid;
    this.selectedConnector.Modbus.port = 502;

    this.connectors.push(this.selectedConnector);
  }

  async loadVariables(driver : ViewDriver)
  {    
    console.log("loading variables");
    let variables = await this.xprojClient.XAUTO_GetVariables(0,1000, driver.Driver.xautodriverid);
    for( let j = 0; j < variables.length; j++)
    {
      let variable = variables[j];
      console.log("loading variable", variable.xautovariableid);
      let modvar = await this.xprojClient.XAUTO_GetVariableModbus(variable.xautovariableid);

      let newViewVar = new ViewVariable();
      newViewVar.Variable = variable;
      newViewVar.Modbus = modvar;
      driver.Variables.push(newViewVar);
    }
    console.log("loading variables done");
  }

  async loadDrivers()
  {
    let drivers = await this.xprojClient.XAUTO_GetDrivers(0, 1000);    
    for(let i = 0; i < drivers.length; i++)
    {    
      let driver = drivers[i];
      if(driver.driver != XAUTO_DriverType.MODBUS)
        continue;

      let modbusdriver = await this.xprojClient.XAUTO_GetDriverModbus(driver.xautodriverid);
      let viewdriver = new ViewDriver();
      viewdriver.Driver = driver;
      viewdriver.Modbus = modbusdriver;
      await this.loadVariables(viewdriver);
      this.connectors.push(viewdriver);
    }
  }

  async ngOnInit() {
    this.loadDrivers();
  }

}
