<main class="content-area">
    <h1>Modbus Connectors</h1>

    <div class="limit-height" style="max-width:1200px;">
        <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedConnector" [clrDgRowSelection]="true" [clrDgLoading]="loadingConnectors">

            <clr-dg-column [clrDgField]="'Driver.enabled'"> Enabled </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.name'" [clrDgSortOrder]="ascSort"> Name </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.description'"> Description </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.defaultxautogroup'"> Default XAutoGroup </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.defaultxgroup'"> Default XGroup </clr-dg-column>

            <clr-dg-row *clrDgItems="let connector of connectors" [clrDgItem]="connector">
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.enabled}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.name}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.description}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.defaultxautogroup}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.defaultxgroup}} </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
                <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[0]">
                    <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Connectors per page</clr-dg-page-size>
                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Modbus Connectors
                </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </div>

    <button class="btn btn-link" (click)="addConnector()">
        <clr-icon shape="plus-circle"></clr-icon>
        New Modbus connector
    </button>
    <button *ngIf="selectedConnector" type="button" class="btn btn-link" [clrLoading]="savingRemovingDriver" (click)="removeDriver();">
        <clr-icon shape="minus-circle"></clr-icon>
        Remove connector
    </button>


    <clr-accordion [clrAccordionMultiPanel]="true" *ngIf="selectedConnector">

        <clr-accordion-panel [clrAccordionPanelOpen]="true">
            <clr-accordion-title>Connector configuration</clr-accordion-title>
            <clr-accordion-content>

                <form clrForm>
                    <clr-input-container>
                        <label>Driver ID </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.xautodriverid" name="xautodriverid" disabled size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Name </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.name" name="drivername" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Description </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.description" name="driverdescription" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Default XAuto Group </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.defaultxautogroup" name="driverdefaultxautogroup" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Default XGroup </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.defaultxgroup" name="driverdefaultxgroup" size="35" />
                    </clr-input-container>

                    <clr-select-container>
                        <label>Type</label>
                        <select clrSelect name="drivermodbustype" [(ngModel)]="selectedConnector.Modbus.type">
                            <option [ngValue]="XAUTO_ModbusType.TCPIP_MASTER">TCPIP MASTER</option>
                            <option [ngValue]="XAUTO_ModbusType.TCPIP_SLAVE">TCPIP SLAVE</option>
                            <option [ngValue]="XAUTO_ModbusType.RTU_MASTER">RTU MASTER</option>
                            <option [ngValue]="XAUTO_ModbusType.RTU_SLAVE">RTU SLAVE</option>
                        </select>
                    </clr-select-container>

                    <clr-input-container *ngIf="selectedConnector.Modbus.type == XAUTO_ModbusType.TCPIP_MASTER">
                        <label>Host</label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Modbus.host" name="driverhost" size="35" />
                    </clr-input-container>
                    <clr-input-container *ngIf="selectedConnector.Modbus.type==XAUTO_ModbusType.TCPIP_MASTER || selectedConnector.Modbus.type==XAUTO_ModbusType.TCPIP_SLAVE">
                        <label>Port</label>
                        <input clrInput type="number" [(ngModel)]="selectedConnector.Modbus.port" name="driverport" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Slave</label>
                        <input clrInput type="number" [(ngModel)]="selectedConnector.Modbus.slave" name="driverslave" size="35" />
                    </clr-input-container>

                    <clr-input-container *ngIf="selectedConnector.Modbus.type==XAUTO_ModbusType.RTU_MASTER || selectedConnector.Modbus.type==XAUTO_ModbusType.RTU_SLAVE">
                        <label>Device</label>
                        <input clrInput type="string" [(ngModel)]="selectedConnector.Modbus.rtudevice" name="driversdevice" size="35" />
                    </clr-input-container>
                    <clr-input-container *ngIf="selectedConnector.Modbus.type==XAUTO_ModbusType.RTU_MASTER || selectedConnector.Modbus.type==XAUTO_ModbusType.RTU_SLAVE">
                        <label>Baud rate</label>
                        <input clrInput type="number" [(ngModel)]="selectedConnector.Modbus.rtubaud" name="driversrtubaud" size="35" />
                    </clr-input-container>
                    <clr-select-container *ngIf="selectedConnector.Modbus.type==XAUTO_ModbusType.RTU_MASTER || selectedConnector.Modbus.type==XAUTO_ModbusType.RTU_SLAVE">
                        <label>Parity</label>
                        <select clrSelect name="driverparity" [(ngModel)]="selectedConnector.Modbus.rtuparity">
                            <option [ngValue]="XAUTO_ModbusRTUParity.None">None</option>
                            <option [ngValue]="XAUTO_ModbusRTUParity.Even">Even</option>
                            <option [ngValue]="XAUTO_ModbusRTUParity.Odd">Odd</option>
                        </select>
                    </clr-select-container>
                    <clr-input-container *ngIf="selectedConnector.Modbus.type==XAUTO_ModbusType.RTU_MASTER || selectedConnector.Modbus.type==XAUTO_ModbusType.RTU_SLAVE">
                        <label>Data bit</label>
                        <input clrInput type="number" [(ngModel)]="selectedConnector.Modbus.rtudatabit" name="driversrtudatabit" size="35" />
                    </clr-input-container>
                    <clr-input-container *ngIf="selectedConnector.Modbus.type==XAUTO_ModbusType.RTU_MASTER || selectedConnector.Modbus.type==XAUTO_ModbusType.RTU_SLAVE">
                        <label>Stop bit</label>
                        <input clrInput type="number" [(ngModel)]="selectedConnector.Modbus.rtustopbit" name="driversrtustopbit" size="35" />
                    </clr-input-container>
                    <clr-select-container *ngIf="selectedConnector.Modbus.type==XAUTO_ModbusType.RTU_MASTER || selectedConnector.Modbus.type==XAUTO_ModbusType.RTU_SLAVE">
                        <label>Mode</label>
                        <select clrSelect name="driversrtumode" [(ngModel)]="selectedConnector.Modbus.rtumode">
                            <option [ngValue]="XAUTO_ModbusRTUMode.RS485">RS485</option>
                            <option [ngValue]="XAUTO_ModbusRTUMode.RS232">RS232</option>
                        </select>
                    </clr-select-container>

                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="driverenabled" [(ngModel)]="selectedConnector.Driver.enabled" />
                            <label>Enabled</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>


                    <button class="btn" [clrLoading]="savingDriver" (click)="saveDriver()">Save</button>

                </form>
            </clr-accordion-content>
        </clr-accordion-panel>

        <clr-accordion-panel [clrAccordionPanelOpen]="false">
            <clr-accordion-title>Connector Variables</clr-accordion-title>
            <clr-accordion-content>
                <div class="limit-height" style="max-width:1200px;">
                    <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedVariable" [clrDgRowSelection]="true" [clrDgLoading]="loadingConnectors">

                        <clr-dg-column [clrDgField]=" 'Variable.enabled'"> Enabled </clr-dg-column>
                        <clr-dg-column [clrDgField]=" 'Modbus.address'" [clrDgColType]=" 'number'"> Address </clr-dg-column>
                        <clr-dg-column [clrDgField]=" 'Modbus.size'" [clrDgColType]=" 'number'"> Size </clr-dg-column>
                        <clr-dg-column [clrDgField]=" 'Variable.name'" [clrDgSortOrder]="ascSort"> Name </clr-dg-column>
                        <!-- <clr-dg-column [clrDgField]=" 'description'"> Description </clr-dg-column> -->
                        <clr-dg-column [clrDgField]=" 'Variable.xautogroup'"> XAutoGroup </clr-dg-column>
                        <clr-dg-column [clrDgField]=" 'Variable.xgroup'"> XGroup </clr-dg-column>
                        <clr-dg-column [clrDgField]=" 'Variable.defaultscriptname'"> Default scriptname </clr-dg-column>

                        <clr-dg-row *clrDgItems="let variable of selectedConnector.Variables" [clrDgItem]="variable">
                            <clr-dg-cell>
                                <clr-toggle-container style="margin-top: 0px;">
                                    <clr-toggle-wrapper>
                                        <input type="checkbox" clrToggle value="true" name="enabled{{variable.Variable.xautovariableid}}" [(ngModel)]="variable.Variable.enabled" />
                                    </clr-toggle-wrapper>
                                </clr-toggle-container>
                            </clr-dg-cell>

                            <clr-dg-cell>
                                <clr-input-container style="margin-top: 0px;">
                                    <input style="width:6em;" clrInput type="number" [(ngModel)]="variable.Modbus.address" name="address{{variable.Variable.xautovariableid}}" size="35" />
                                </clr-input-container>
                            </clr-dg-cell>
                            <clr-dg-cell>
                                <clr-input-container style="margin-top: 0px;">
                                    <input style="width:4em;" clrInput type="number" [(ngModel)]="variable.Modbus.size" name="size{{variable.Variable.xautovariableid}}" size="35" />
                                </clr-input-container>
                            </clr-dg-cell>

                            <clr-dg-cell>
                                <clr-input-container style="margin-top: 0px;">
                                    <input style="width:90%;" clrInput name="name{{variable.Variable.xautovariableid}}" [(ngModel)]="variable.Variable.name" />
                                </clr-input-container>
                            </clr-dg-cell>

                            <!-- <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.description}} </clr-dg-cell> -->
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.xautogroup}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.xgroup}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.defaultscriptname}} </clr-dg-cell>
                        </clr-dg-row>

                        <clr-dg-footer>
                            <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[1]">
                                <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Variables per page</clr-dg-page-size>
                                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Variables
                            </clr-dg-pagination>
                        </clr-dg-footer>
                    </clr-datagrid>
                </div>

                <clr-dropdown [clrCloseMenuOnItemClick]="true">
                    <button clrDropdownTrigger aria-label="More actions">
                      <clr-icon shape="ellipsis-vertical" size="12"></clr-icon>
                      <!-- <clr-icon shape="caret down"></clr-icon> -->
                    </button>
                    <clr-dropdown-menu *clrIfOpen>
                        <div aria-label="Disable all" clrDropdownItem (click)="disableAll()">Disable All</div>
                        <div aria-label="Enable all" clrDropdownItem (click)="enableAll()">Enable All</div>
                        <div aria-label="Save all" clrDropdownItem (click)="saveAll()">Save All</div>
                        <div aria-label="Export" clrDropdownItem (click)="export()">Export</div>
                    </clr-dropdown-menu>
                </clr-dropdown>

                <button class="btn btn-link" (click)="addVariable()">
                    <clr-icon shape="plus-circle"></clr-icon>
                    New Modbus variable
                </button>
                <button *ngIf="selectedConnector" type="button" class="btn btn-link" [clrLoading]="savingRemovingVariable" (click)="removeVariable();">
                    <clr-icon shape="minus-circle"></clr-icon>
                    Remove variable
                </button>


                <form clrForm *ngIf="selectedVariable">
                    <clr-input-container>
                        <label>Variable ID </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.xautovariableid" name="variablexautovariableid" disabled size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Name </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.name" name="variablename" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Description </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.description" name="variabledescription" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>XAuto Group </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.xautogroup" name="variablexautogroup" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>XGroup </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.xgroup" name="variablexgroup" size="35" />
                    </clr-input-container>


                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="uselineratransform" [(ngModel)]="selectedVariable.Variable.transform.uselinetransform" />
                            <label>Use Line Transform</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>

                    <clr-input-container *ngIf="selectedVariable.Variable.transform.uselinetransform">
                        <label>Line Transform K</label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Variable.transform.linetransform_k" name="linetransk" size="35" />
                    </clr-input-container>
                    <clr-input-container *ngIf="selectedVariable.Variable.transform.uselinetransform">
                        <label>Line Transform M</label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Variable.transform.linetransform_m" name="linetransm" size="35" />
                    </clr-input-container>

                    <!-- <clr-select-container>
                        <label>Data type</label>
                        <select clrSelect name="coltype" [(ngModel)]="selectedVariable.Variable.type">
                            <option [ngValue]="XDataType.Float32">Float32</option>
                            <option [ngValue]="XDataType.Float64">Float64</option>
                            <option [ngValue]="XDataType.UInt8">UInt8</option>
                            <option [ngValue]="XDataType.Int32">Int32</option>
                            <option [ngValue]="XDataType.Int64">Int64</option>
                            <option [ngValue]="XDataType.UInt128">UInt128</option>
                            <option [ngValue]="XDataType.Timestamp">Timestamp</option>
                            <option [ngValue]="XDataType.String">String</option>
                        </select>
                    </clr-select-container> -->

                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="variablewritable" [(ngModel)]="selectedVariable.Variable.writable" />
                            <label>Writable</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>
                    <clr-input-container>
                        <label>Sample interval (seconds) </label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Variable.sampleintervalseconds" name="variablesampleinterval" size="35" />
                    </clr-input-container>
                    <clr-input-container>
                        <label>Flush interval (seconds) </label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Variable.flushintervalseconds" name="variableflushinterval" size="35" />
                    </clr-input-container>



                    <clr-select-container>
                        <label>Modbus data type</label>
                        <select clrSelect name="drivermodbusvariabletype" [(ngModel)]="selectedVariable.Modbus.variabletype">
                            <option [ngValue]="XAUTO_ModbusVariableType.Bits">Bits</option>

                            <option [ngValue]="XAUTO_ModbusVariableType.Int8">Signed Integer 8 bits</option>
                            <option [ngValue]="XAUTO_ModbusVariableType.UInt8">Unsigned Integer 8 bits</option>

                            <option [ngValue]="XAUTO_ModbusVariableType.Int16">Signed Integer 16 bits</option>
                            <option [ngValue]="XAUTO_ModbusVariableType.UInt16">Unsigned Integer 16 bits</option>

                            <option [ngValue]="XAUTO_ModbusVariableType.Int32">Signed Integer 32 bits</option>
                            <option [ngValue]="XAUTO_ModbusVariableType.UInt32">Unsigned Integer 32 bits</option>

                            <option [ngValue]="XAUTO_ModbusVariableType.Int64">Signed Integer 64 bits</option>
                            <option [ngValue]="XAUTO_ModbusVariableType.UInt64">Unsigned Integer 64 bits</option>

                            <option [ngValue]="XAUTO_ModbusVariableType.Float32">Floating-Point 32 bits</option>
                            <option [ngValue]="XAUTO_ModbusVariableType.Float64">Floating-Point 64 bits</option>
                        </select>
                    </clr-select-container>

                    <clr-select-container>
                        <label>Register order</label>
                        <select clrSelect name="drivermodbusregisterorder" [(ngModel)]="selectedVariable.Modbus.variableregisterorder">
                            <option [ngValue]="XAUTO_ModbusVariableRegisterOrder.LEFT_TO_RIGHT">Left to right</option>
                            <option [ngValue]="XAUTO_ModbusVariableRegisterOrder.RIGHT_TO_LEFT">Right to left</option>
                        </select>
                    </clr-select-container>

                    <clr-select-container>
                        <label>Byte order (inside register)</label>
                        <select clrSelect name="drivermodbusbyteorder" [(ngModel)]="selectedVariable.Modbus.variablebyteorder">
                            <option [ngValue]="XAUTO_ModbusVariableByteOrder.LEFT_TO_RIGHT">Left to right</option>
                            <option [ngValue]="XAUTO_ModbusVariableByteOrder.RIGHT_TO_LEFT">Right to left</option>
                        </select>
                    </clr-select-container>

                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="variableinput" [(ngModel)]="selectedVariable.Modbus.input" />
                            <label>Input</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>

                    <clr-input-container>
                        <label>Address</label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Modbus.address" name="variableaddress" size="35" />
                    </clr-input-container>
                    <clr-input-container>
                        <label>Size</label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Modbus.size" name="variablesize" size="35" />
                    </clr-input-container>


                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="variableenabled" [(ngModel)]="selectedVariable.Variable.enabled" />
                            <label>Enabled</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>

                    <button class="btn" [clrLoading]="savingVariable" (click)="saveVariable()">Save</button>

                </form>

            </clr-accordion-content>
        </clr-accordion-panel>
    </clr-accordion>
</main>