import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'xproj-editintegrationserver-mqtt',
  templateUrl: './editintegrationserver-mqtt.component.html',
  styleUrls: ['./editintegrationserver-mqtt.component.scss']
})
export class EditintegrationserverMqttComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
