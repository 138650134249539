import 'reflect-metadata';
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
import { DataFilter } from '../../../filters/data-filter/data-filter-service';
import { GroupSelectionTypes, WidgetConfig } from '../../widget-config-service';
import { Aggregation, BaseQuery, FilterLogicalGroupType, Transformation, XDataType } from '../../../XProjector/xprojector-client-service';
import { ChartWidgetConfig, ChartWidgetQuery } from '../../chart/chart-widget-config/xproj-chart-widget-config-service';
import { Guid } from '../../../utils/guid-service';

@jsonObject
export class Cubical3dColumnConfig {

  @jsonMember
  public Id : string = Guid.newGuid();

  @jsonMember
  public ColumnName : string = '';

  @jsonMember
  public MatchColumnValue : string = '';

  @jsonMember
  public Label : string = '';

  @jsonMember
  public Unit : string = '';

  @jsonMember
  public Position : string = '';

  @jsonMember
  public ColumnOutName : string = '';

  // @jsonMember
  // public Hidden : boolean = false;

  @jsonMember
  public Clickable : boolean = false;

  @jsonMember
  public ClickableOutputColumnId : string = '';

  @jsonMember
  public Transform: Aggregation = Aggregation.NONE;

  @jsonMember
  public Datatype : XDataType = XDataType.Number;

  @jsonMember
	public UseAggregationInputParameter : boolean = false;

  @jsonMember
  public AggregationInputParameterId: string = '';

  @jsonMember
  public PrependText : string = "";

  @jsonMember
  public AppendText : string = "";

  @jsonMember
  public SetText : boolean = false;

  @jsonMember
  public SetColor : boolean = false;

  @jsonArrayMember(String)
  public SetTextOnKeyKeys : String[] = [];

  @jsonArrayMember(String)
  public SetTextOnKeyValues : String[] = [];

  @jsonArrayMember(Number)
  public TresholdsInclusive : Number[] = [];

  @jsonArrayMember(String)
  public HTMLColorValues : String[] = [];
}

@jsonObject
export class Cubical3dWidgetQuery {

  public Id : string = Guid.newGuid();

  @jsonMember
  public Query : BaseQuery = new BaseQuery();

  @jsonMember
  public ProjectionId : string = '';

  @jsonMember
  public MaxItems : number = 1000;

  @jsonMember
  public UseGrouping : boolean = false;

  // @jsonMember
  // public UseColumnMatching : boolean = false;

  // @jsonMember
  // public MatchColumnName : string = '';

  // @jsonMember
  // public ValueColumnName : string = '';

  @jsonMember
  public OnDataUpdateLuaScript : string  ="";

  @jsonMember
  public BeforeRenderLuaScript : string = "";

  @jsonMember
  public GroupingTransform : Transformation = Transformation.NONE;

  @jsonMember
	public UseTransformInputParameter : boolean = false;

  @jsonMember
  public TransformInputParameterId: string = '';

  @jsonArrayMember(String)
  public Group : string[] = [];

  @jsonArrayMember(Cubical3dColumnConfig)
  public ColumnConfigs : Cubical3dColumnConfig[] = [];

  @jsonArrayMember(DataFilter)
  public DataFilters : DataFilter[] = [];

  @jsonMember
  public FilterLogicalGroupType : FilterLogicalGroupType = FilterLogicalGroupType.AND;

  @jsonMember
  public timestampColumnName : string = '';

  @jsonMember
  public defaultSortColumnName : string = '';

  @jsonMember
  public defaultSortDescending : boolean = false;

  @jsonMember
	public UseProjectionInputParameter : boolean = false;

  @jsonMember
  public ProjectionInputParameterId: string = '';

  @jsonMember
  public GroupSelectionType : GroupSelectionTypes = GroupSelectionTypes.GROUP;

  @jsonMember
  public GroupInputParameterId: string = '';

  @jsonArrayMember(String)
  public GroupInputParameterIds: string[] = [];}


@jsonObject
export class Cubical3dWidgetConfig extends WidgetConfig{

  @jsonArrayMember(Cubical3dWidgetQuery)
  public ConfigQueries : Cubical3dWidgetQuery[] = [];

  @jsonMember
  public OnDataUpdateLuaScript : string = "";

  @jsonMember
  public InitLuaScript : string = "";


  @jsonMember
  public BeforeRenderLuaScript : string = "";  

  //public constructor();
  public constructor(chartConfig? : ChartWidgetConfig) {
    super();

    if (chartConfig) {
      chartConfig.ConfigQueries.forEach(cq => {
        let tableWidgetQuery = TypedJSON.parse(TypedJSON.stringify(cq, ChartWidgetQuery), Cubical3dWidgetQuery);

        tableWidgetQuery.ProjectionId = cq.Query.targetprojectionid;
        cq.Query.targetgroup.forEach(g => {
          tableWidgetQuery.Group.push(g);
        });

        let colConfig = new Cubical3dColumnConfig();
        colConfig.ColumnName = 'x';
        colConfig.Label = cq.Xaxis

        tableWidgetQuery.ColumnConfigs.push(colConfig);

        cq.YAxesConfigs.forEach(yconfig => {
          let colConfig = new Cubical3dColumnConfig();
          colConfig.ColumnName = yconfig.ColumnOutName;
          colConfig.Label = yconfig.Label;
          colConfig.Unit = yconfig.Unit;

          tableWidgetQuery.ColumnConfigs.push(colConfig);
        });

        tableWidgetQuery.Query.maxitems = tableWidgetQuery.MaxItems;

        this.ConfigQueries.push(tableWidgetQuery);
      });
    }
  }

  public Clone() : Cubical3dWidgetConfig {
    return TypedJSON.parse(TypedJSON.stringify(this, Cubical3dWidgetConfig), Cubical3dWidgetConfig);
  }

  public GetSubscriprionData(): { projectionId: string, group : string[] }[] {
    let result : { projectionId: string, group : string[] }[] = [];
    this.ConfigQueries.forEach(c => {
      if (c.Query && c.Query.targetprojectionid?.length > 0) {
        result.push({projectionId : c.Query.targetprojectionid, group : c.Query.targetgroup})
      }
    });
    return result;
  }

  public CanExportToExcel : boolean = false;

  public CanExportToImage : boolean = false;
}
